import Header from '../../component/header/header.jsx'
import Slider from '../../component/content/slider.jsx'
import ProductSlide from '../../component/content/group.jsx'
import Popular  from '../../component/content/popular.jsx'
import ChoiProduct from '../../component/content/choiProduct.jsx'
import Content from '../../component/content/contentMainPage.jsx'
import ContactHomepage from '../../component/contact/contactHomepage.jsx'
import Socialbox from '../../component/content/socail.jsx'
import SubProduct from '../../component/content/subProduct.jsx'
import MutProduct from '../../component/content/mutProduct.jsx'
import Footer from '../../component/footer/footer.jsx'
const HomePage = () => {
    return (
        <div class="homepage h-[auto]">

          <Header />   
          <Slider /> 
          <Content /> 
          <ProductSlide /> 
          <ChoiProduct />
          <Popular />
          <MutProduct />
          <SubProduct />
          <Socialbox />
          <ContactHomepage />
          <Footer /> 
        </div>
    )
}


export default HomePage;