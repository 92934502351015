const ContactHomepage = () => {
    return (
        <div class="max-w-[1200px] ml-auto mr-auto  mt-2">
      {/* <div class="ml-2">
        <h1 class="text-[23px] font-bold underline underline-offset-8">Liên Hệ Chúng Tôi</h1>
        <p class="mt-5">
          Chào mừng bạn đến với CÔNG TY TNHH SX-TM-DV TÍN HÀO - nơi chúng tôi không chỉ cung cấp sản phẩm và dịch vụ xuất sắc mà còn tạo ra những trải nghiệm đặc biệt cho khách hàng. Hãy liên hệ với chúng tôi để biết thêm chi tiết hoặc để đặt hàng ngay hôm nay.
        </p>
        
        <div class="mt-2">
          <h2>Thông Tin Liên Hệ</h2>
          <p>
            <strong>Địa Chỉ:</strong> 124 Hậu Giang - phường 11 - quận 6
          </p>
          <p>
            <strong>Điện Thoại:</strong> 0283-9625-428
          </p>
          <p>
            <strong>Email:</strong> thctinhao@gmail.com
          </p>
        </div>
      </div>
      <div class="mt-2 ml-2">
        <h2 class="underline underline-offset-8 font-bold mt-5 mb-2">Chúng Tôi Ở Đây Để Hỗ Trợ</h2>
        <p class="mt-2">
          Với đội ngũ nhân viên chuyên nghiệp và nhiệt huyết, chúng tôi cam kết đem đến sự hỗ trợ tận tâm nhất cho bạn. Hãy để chúng tôi biết cách chúng tôi có thể giúp đỡ bạn ngay hôm nay.
        </p>
        <p class="mt-2">
          <strong>Hãy liên hệ với chúng tôi:</strong> mỗi cuộc gọi, email, hay thậm chí là một cuộc trò chuyện ngắn có thể là bước quan trọng trong hành trình của bạn với CÔNG TY TNHH SX-TM-DV TÍN HÀO.
        </p>
      </div> */}
        </div>
    )
}

export default ContactHomepage