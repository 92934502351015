import Header from "../../component/header/header.jsx"
import Socialbox from '../../component/content/socail.jsx'
import AboutUsContent from "../../component/contact/aboutus.jsx"
import Footer from "../../component/footer/footer.jsx"
const AboutUs = () => {
 
    return (
        <div>
            <>
             <Header />
             <AboutUsContent />
             <Socialbox />
             <Footer />
            </>
        </div>
    )
}

export default AboutUs;
