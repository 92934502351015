import Header from '../../component/header/header.jsx'
import Detail from '../../component/detailProduct/detail.jsx'
import Footer from '../../component/footer/footer.jsx'
import Socialbox from '../../component/content/socail.jsx'

const PageDetail = () => {
      return (
  <div>
    <Header />
    <Detail />
  
    <Socialbox />
    <Footer />
  </div>
   
  );
}


export default PageDetail;