import {Link} from "react-router-dom";
import {useState,useEffect} from "react";
import axios  from "axios";


const Related = (category ) => {
    const [products,setProducts] = useState();
    const ApiTHC = "https://test-api-bhc7.onrender.com";
        // functuin convert
   function formatPrice(priceValue) {
    return priceValue.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0,
    });
  }
  const route = category.category

    useEffect(() => {
        // Fetch product data ofrm your API endpoint
        axios.get(`https://test-api-bhc7.onrender.com/api/products/${route}`)
          .then((response) => setProducts(response.data.products)) // Assuming the product data is under the "products" key
          .catch((error) => console.error('Error fetching products:', error));
      }, []);

    return (
        <div class="max-w-[1200px] ml-auto mr-auto p-3">
          <p class="text-[22px] mt-2 font-bold">Các sản phẩm liên quan</p>
          <div class="grid grid-cols-2 lg:grid-cols-5 sm:grid-cols-3 gap-4 mb-4 mt-10">
            {
               products ? (
                products.map((product) => (
                  <div key={product._id} className="flex border border-[#0004] ">
                  <Link to={`/product/${product.slug}`} class="">
                   <div class="group oveflow-hidden min-h-[380px]   shadow-lg relative overflow-hidden cursor-pointer duration-300">
                   <div className="max-w-[350px] flex items-center relative">
                   <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-[1] group-hover:animate-shine" />
                  <img
                    src={product.photo}
                    className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-100 group-hover:opacity-0 transition-opacity duration-300 mt-5"
                    alt=""
                  />
                 <img
                   src={product.sub_photo}
                   className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 left-[50%] translate-x-[-50%] mt-5"
                   alt=""
                  />
                </div>
                   
                    <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
                       <div class="p-2">
                 
                       <h1 class="font-bold mb-0  group-hover:text-pur duration-300 mt-3">{product.name }</h1>
                        <div class=" font-medium flex justify-between item-end absolute bottom-0 left-0 right-0 px-2 pb-3 ">
                        <p>TPHCM</p>
                        <p> {product.description }</p>
                        </div>
                       </div>
                   </div>
                   </Link>
                  </div>
                 ))
              ) : (
                <p>Loading</p>
              )
            }
        
           </div>
           </div>
    )
}


export default Related;