import Header from "../../component/header/header.jsx";
import Search from "../../component/content/search";
import Socialbox from '../../component/content/socail.jsx'
import Footer from "../../component/footer/footer.jsx";
const SearchPage = () => {
    return (
        <div>
            <Header />
            <Search />
            <Socialbox />
            <Footer />
        </div>
    )
}

export default SearchPage;