 import {useDispatch,useSelector} from "react-redux"
 import { getProducts as  fetchProducts} from "../../redux/action/apiRequest"
 import { connect } from 'react-redux';
 import  {useState,useEffect} from "react"
 import thunk from 'redux-thunk';
 import {Link} from "react-router-dom"
const Popular = () => {
  const dispatch = useDispatch();
  const getProducts = useSelector((state) => state.product.items.products);
  function formatPrice(priceValue) {
 
    return priceValue.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0,
    });
  }
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);
    return (
        <div class="h-[auto] max-w-[1200px] ml-auto mr-auto mt-[0px] px-3">
            <div class="text-center">
             <h1 class="font-bold text-[25px] pt-5">Các sản phẩm phổ biến </h1>
             <p class="text-smothwhite font-medium">Chúng tôi cung cấp những sản phẩm chất lượng và đảm bảo sự thuận tiện</p>
           </div>
           <div class="grid grid-cols-2 lg:grid-cols-5 sm:grid-cols-3 gap-4 mb-4 mt-10">
            {
              getProducts ? (
                getProducts.product.map((product) => (
                  <div key={product._id} className="flex border border-[#0004] ">
                  <Link to={`/product/${product.slug}`} class="">
                   <div class="group oveflow-hidden min-h-[380px]   shadow-lg relative overflow-hidden cursor-pointer duration-300">
                   <div className="max-w-[350px] flex items-center relative">
                   <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-[1] group-hover:animate-shine" />
                  <img
                    src={product.photo}
                    className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-100 group-hover:opacity-0 transition-opacity duration-300 mt-5"
                    alt=""
                  />
                 <img
                   src={product.sub_photo}
                   className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 left-[50%] translate-x-[-50%] mt-5"
                   alt=""
                  />
                </div>
                   
                    <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
                       <div class="p-2">
                 
                       <h1 class="font-bold mb-0  group-hover:text-pur duration-300 mt-3">{product.name }</h1>
                        <div class=" font-medium flex justify-between item-end absolute bottom-0 left-0 right-0 px-2 pb-3 ">
                        <p>TPHCM</p>
                        <p> {product.description }</p>
                        </div>
                       </div>
                   </div>
                   </Link>
                  </div>
                 ))
              ) : (
                <p>Loading</p>
              )
            }
        
           </div>
          
        </div>
    )
}

export default Popular;