import {Link,useNavigate} from 'react-router-dom'
import {BsFillHouseCheckFill} from 'react-icons/bs';
import { useSelector } from 'react-redux';
import {MdLogout,MdLogin} from "react-icons/md"
import {GoDash} from 'react-icons/go'
import { useState } from 'react';
import {AiOutlineSearch,AiOutlineMenu } from 'react-icons/ai'
import {RiArrowRightSLine} from 'react-icons/ri'
import "../../style/animation.css"
const Menu = ({animationMenu}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const userName = useSelector((state) => state.root.auth.login.currentuser);
    const [modal,setModal] = useState(false)
    const handleModal = () => {
       setModal(!modal)
    }
    const handleLogout = () => {
        localStorage.removeItem("persist:root")
        window.location.reload();
     }
     const history = useNavigate();

    return (
       
            <div class={`bg-white min-h-[100vh] fixed inset-0 w-[90%] z-50 ${animationMenu} `}>
          
              <div >
                <div class="flex flex-col justify-between items-start  min-h-[100vh] p-2">
                <ul class="flex flex-col justify-center items-center h-full  font-medium   cursor-pointer text-[20px] mt-10 text-right w-full">
                 <div>
                 <img class="w-[150px] md:w-[200px]  ml-auto mr-auto" src="https://ik.imagekit.io/39wvgoqre/logo/370323579_6278720615590880_1703899035069007565_n.png?updatedAt=1699513666493" alt="" srcset="" />
                 <h1 class="md:text-[18px] font-bold text-left  text-[14px]">CÔNG TY TNHH SX-TM-DV TÍN HÀO</h1>
               
                 </div>
                  <li class="flex w-full p-4 "><Link to="/" class="flex justify-between w-full mt-5 sm:text-[18px] text-[16px]"> Trang chủ <RiArrowRightSLine class="text-[30px]"/></Link> </li>
                  <li class="w-full p-4  flex"><Link to="/contact" class="flex justify-between w-full sm:text-[18px] text-[16px]" >Liên hệ <RiArrowRightSLine class="text-[30px]"/></Link></li>
                  <li class="w-full p-4 flex"><Link to="/aboutUs" class="flex justify-between w-full sm:text-[18px] text-[16px]"> Về chúng tôi <RiArrowRightSLine class="text-[30px]"/></Link></li>
                  <li class="flex w-full p-4 sm:text-[18px] text-[14px]" onClick={handleModal}><AiOutlineMenu  class=" mr-5 ml-0.5 mt-1 "/><p>Danh mục sản phẩm</p></li>
                  <div class={` shadow-lg w-full flex justify-start transition-height duration-500 linear ${modal ? 'h-[auto] py-5 px-5' : 'h-[0] py-0 px-0'}  overflow-hidden`}>
                    <ul class="w-full">
                    <Link to="/product/category/bo-lau-nha" class="w-full items-center  flex justify-between hover:bg-gradient-to-r from-pur to-darkpur hover:text-white duration-300 sm:text-[18px] text-[16px]"><li class="p-3 w-full  cursor-pointer text-left"> Bộ lau nhà </li><RiArrowRightSLine class="text-[30px]"/></Link> 
                  <Link to="/product/category/cay-lau-nha" class="w-full items-center flex justify-between hover:bg-gradient-to-r from-pur to-darkpur hover:text-white duration-300 sm:text-[18px] text-[16px]"><li class="p-3 w-full cursor-pointer text-left">Cây lau nhà </li> <RiArrowRightSLine class="text-[30px]"/></Link> 
                  <Link to="/product/category/mut" class="w-full flex justify-between items-center  hover:bg-gradient-to-r from-pur to-darkpur hover:text-white duration-300 sm:text-[18px] text-[14px]"><li class="p-3  cursor-pointer text-left">Mút </li><RiArrowRightSLine class="text-[30px]"/></Link> 
                  <Link to="/product/category/phu-kien" class="w-full items-center  flex justify-between hover:bg-gradient-to-r from-pur to-darkpur hover:text-white duration-300 sm:text-[18px] text-[16px]"><li class="p-3  cursor-pointer text-left">Phụ kiện </li><RiArrowRightSLine class="text-[30px]"/></Link> 
                   </ul>
                  </div>
                </ul>
                <ul class="mb-[100px] text-[16px] justify-start mb-2 ml-1 w-full">
               <li class="mx-2 mb-5 underline underline-offset-2"><span class="font-bold no-underline">Email:</span> thctinhao@gmail.com</li>
               <li class="mx-2 mb-5 underline underline-offset-2"><a href="tel:028 39625428"><span class="font-bold no-underline">SĐT:</span> 028 39625428</a></li>
               <li class="mx-2 mb-5 underline underline-offset-2"> <a href="https://zalo.me/0933627688"><span class="font-bold no-underline">Zalo:</span> 028 39625428</a></li>
             </ul>
                </div>
               
             </div>
            </div>
         
    )
}

export default Menu;