import Header from "../../component/header/header";
import Contact from "../../component/contact/contact";
import Footer from "../../component/footer/footer";

const ContactPage = () => {
    return (
        <div>
           <Header /> 
           <Contact />
           <Footer />
        </div>
    )
}

export default ContactPage;