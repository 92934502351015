
import {AiOutlineMenu} from 'react-icons/ai'
// import ChoiProduct from '../../component/content/choiProduct.jsx'
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from "axios"
import {useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
const ApiTHC = "https://test-api-bhc7.onrender.com";
const ProductSlide = ({ onCategoryClick }) => {
    const [products,setProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("bo-lau-nha");
    const [modal,setModal] = useState(false);
    const showModal = () => {
       return setModal(!modal)
    }
    const handleCategoryClick = (category) => {
       setSelectedCategory(category)
    };
    // functionn convert
  function formatPrice(priceValue) {
 
    return priceValue.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0,
    });
  }
    useEffect(() => {
      // Fetch product data ofrm your API endpoint
      axios.get(`${ApiTHC}/api/products/${selectedCategory}`)
        .then((response) => setProducts(response.data.products)) // Assuming the product data is under the "products" key
        .catch((error) => console.error('Error fetching products:', error));
    }, [selectedCategory]);
    return ( 
        <div class='h-[auto] max-w-[1200px] mb-[20px] ml-auto mr-auto mt-[20px] p-3'>
           <div class="text-center">
             <h1 class="font-bold text-[25px]"><span class="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-pur">Các sản phẩm bán chạy</span></h1>
             <p class="text-smothwhite font-medium">Chúng tôi cung cấp những sản phẩm chất lượng và đảm bảo sự thuận tiện</p>
           </div>
           <ul class="block justify-center mt-4 mb-7 md:hidden">
           <li class="mx-2 flex ml-auto mr-auto justify-center  max-w-[300px] text-center border-2 py-2 px-5 hover:bg-pur hover:text-white hover:border-[pur] duration-300 cursor-pointer   rounded-3xl font-bold " onClick={showModal}><AiOutlineMenu  class="mt-1 mr-2 "/>Danh mục
      
           </li>
           <div>
           <ul className={`mt-2 shadow-lg rounded justify-center  mb-10 max-w-[350px] ml-auto mr-auto bg-white   transition-height duration-300 ease-in-out  ${modal ? 'h-[auto] py-10 px-5' : 'h-[0] py-0 px-0'} overflow-hidden`}>
           <li className={`${  selectedCategory === 'bo-lau-nha' ? 'bg-gradient-to-r from-pur to-darkpur text-white' : ''} mx-2 border-2 py-2  max-w-[350px] ml-auto mr-auto  px-5 hover:bg-gradient-to-r from-pur to-darkpur hover:text-white hover:border-[pur] duration-300 cursor-pointer rounded-3xl font-bold mb-4`} onClick={() => handleCategoryClick("bo-lau-nha")}>Bộ lau nhà</li>
             <li className={`${  selectedCategory === 'cay-lau-nha' ? 'bg-gradient-to-r from-pur to-darkpur text-white' : ''} mx-2 border-2 py-2 max-w-[350px] ml-auto mr-auto px-5 hover:bg-gradient-to-r from-pur to-darkpur hover:bg-pur hover:text-white hover:border-[pur] duration-300 cursor-pointer rounded-3xl font-bold mb-4`} onClick={() => handleCategoryClick("cay-lau-nha")}>Cây lau nhà</li>
           
             <li className={`${  selectedCategory === 'mut' ? 'bg-gradient-to-r from-pur to-darkpur text-white' : ''} mx-2 border-2 py-2  max-w-[350px] ml-auto mr-auto  px-5 hover:bg-gradient-to-r from-pur to-darkpur hover:text-white hover:border-[pur] duration-300 cursor-pointer rounded-3xl font-bold mb-4`} onClick={() => handleCategoryClick("mut")}>Muốt</li>
             <li className={`${  selectedCategory === 'phu-kien' ? 'bg-gradient-to-r from-pur to-darkpur text-white' : ''}  mx-2 border-2 py-2  max-w-[350px] ml-auto mr-auto  px-5 hover:bg-gradient-to-r from-pur to-darkpur hover:text-white hover:border-[pur] duration-300 cursor-pointer rounded-3xl font-bold `} onClick={() => handleCategoryClick("phu-kien")}>Phụ kiện</li>
           </ul>
            </div>
           </ul>
           <ul  class="hidden justify-center mt-4 mb-10 md:flex " >
           <li onClick={() => handleCategoryClick('bo-lau-nha')}
                  className={`w-[auto] border-2 text-center flex justify-center items-center p-2 px-4  rounded-full ml-2 cursor-pointer font-bold ${
                  selectedCategory === 'bo-lau-nha' ? 'bg-gradient-to-r from-pur to-darkpur text-white' : ''
                 }`}>Bộ lau nhà</li>
           <li onClick={() => handleCategoryClick('cay-lau-nha')}
                  className={`w-[auto] border-2 text-center flex justify-center items-center p-2 px-4  rounded-full ml-2 cursor-pointer font-bold ${
                  selectedCategory === 'cay-lau-nha' ? 'bg-gradient-to-r from-pur to-darkpur text-white' : ''
                 }`}>Cây lau nhà</li>
               
        
             <li onClick={() => handleCategoryClick('mut')}
                  className={`w-[auto] border-2 text-center flex justify-center items-center p-2 px-4 rounded-full ml-2 cursor-pointer font-bold ${
                  selectedCategory === 'mut' ? 'bg-gradient-to-r from-pur to-darkpur text-white' : ''
                 }`}>Mút </li>
             <li onClick={() => handleCategoryClick('phu-kien')}
                  className={`w-[auto] border-2 text-center flex justify-center items-center p-2 px-4  rounded-full ml-2 cursor-pointer font-bold ${
                  selectedCategory === 'phu-kien' ? 'bg-gradient-to-r from-pur to-darkpur text-white' : ''
                 }`}>Phụ kiện</li>
           </ul>
           <div class="grid grid-cols-2 lg:grid-cols-5 sm:grid-cols-3 gap-4 mb-4 mt-5">
            {products.map((product) => (
            
            <div key={product._id} className="flex border border-[#0004] ">
            <Link to={`/product/${product.slug}`} class="">
             <div class="group oveflow-hidden min-h-[380px]   shadow-lg relative overflow-hidden cursor-pointer duration-300">
             <div className="max-w-[350px] flex items-center relative">
             <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-[1] group-hover:animate-shine" />
            <img
              src={product.photo}
              className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-100 group-hover:opacity-0 transition-opacity duration-300 mt-5"
              alt=""
            />
           <img
             src={product.sub_photo}
             className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 left-[50%] translate-x-[-50%] mt-5"
             alt=""
            />
          </div>
             
              <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
                 <div class="p-2">
           
                 <h1 class="font-bold mb-0  group-hover:text-pur duration-300 mt-3">{product.name }</h1>
                  <div class=" font-medium flex justify-between item-end absolute bottom-0 left-0 right-0 px-2 pb-3 ">
                  <p>TPHCM</p>
                  <p> {product.description }</p>
                  </div>
                 </div>
             </div>
             </Link>
            </div>
          ))} 
            
       
           </div>
           {/* <Banner /> */}
        </div>
    )
}

export default ProductSlide;