import '../../style/animation.css'
import { swiperData }from '../../data/swiperdata.js'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



// import required modules
import { Navigation,Autoplay} from 'swiper/modules';

// const swiperData = [
//   {
//     "heading": "Cây Lau Nhà Tự Vắt 360 Độ Inox THC-8S",
//     "paragraph":"Sản phẩm Bộ Lau Nhà Tự Vắt 360 Độ Spin Mop 2 In 1 là một sản phẩm có thiết kế hiện đại, với thân cây được làm từ inox cao cấp, chắc chắn, bền bỉ. Mâm lau được làm từ inox chống rỉ sét, giúp lau sạch mọi vết bẩn trên sàn nhà",
//     "img":"https://ik.imagekit.io/39wvgoqre/THC%20/TH%208S/TH%208S/1_1.png?updatedAt=1702109195287",
//     "Link_shoppe":"",
//     "link_slug":"",
//      "key":1,
//   },
//   {
//     "heading": "Cây Lau Nhà Tự Vắt 360 Độ Inox THC-8S",
//     "paragraph":"Sản phẩm Bộ Lau Nhà Tự Vắt 360 Độ Spin Mop 2 In 1 là một sản phẩm có thiết kế hiện đại, với thân cây được làm từ inox cao cấp, chắc chắn, bền bỉ. Mâm lau được làm từ inox chống rỉ sét, giúp lau sạch mọi vết bẩn trên sàn nhà",
//     "img":"https://ik.imagekit.io/39wvgoqre/THC%20/TH%208S/TH%208S/1_1.png?updatedAt=1702109195287",
//     "Link_shoppe":"",
//     "link_slug":"",
//     "key":2
//   },
// ]
const Slider = () => {
  return (

   <div class=" h-[auto] p-3  bg-[#fff] shadow-lg">
     {/* <div class="grid md:grid-cols-2 gap-4 mb-4 grid-cols-1">
       <div class="overflow-hidden group cursor-pointer shadow-xl relative">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
         <img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/BANNER%20NEW.png?updatedAt=1701931966333" alt="" srcset="" />
       </div>
       <div class="overflow-hidden group cursor-pointer shadow-xl relative">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
         <img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/TH%2016.png?updatedAt=1701931965030" alt="" srcset="" />
       </div>
     </div>
     <div class="md:grid grid-cols-3  hidden gap-4 mt-4 ">
       <div class="overflow-hidden group cursor-pointer relative">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
         <img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/TH%20108.png?updatedAt=1701931963458" alt="" srcset="" />
       </div>
       <div class="overflow-hidden group cursor-pointer relative">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
         <img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/TH%201283.png?updatedAt=1701931963493" alt="" srcset="" />
       </div>
       <div class="overflow-hidden group cursor-pointer relative">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
         <img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/TH%20166.png?updatedAt=1701931963262" alt="" srcset="" />
       </div>
     </div> */}
    {/* <div class="swiper-slide">
    <div class="grid grid-cols-2 max-w-[1200px] mt-[300px]  h-[80vh] ml-auto mr-auto ">
       <div class="flex items-ceter flex-col ">
         <h1 class="font-[900] text mb-2 text-[50px] mt-10 mb-2">Cây Lau Nhà Tự Vắt 360 Độ Inox THC-8S</h1>
         <p class="mb-[40px] text-[18px] mt-[20px] font-semibold">Sản phẩm Bộ Lau Nhà Tự Vắt 360 Độ Spin Mop 2 In 1 là một sản phẩm có thiết kế hiện đại, với thân cây được làm từ inox cao cấp, chắc chắn, bền bỉ. Mâm lau được làm từ inox chống rỉ sét, giúp lau sạch mọi vết bẩn trên sàn nhà</p>
         <ul class="flex rounded-lg mt-5">
          <li class="bg-[#ee4d2d] text-[18px] text-white rounded-md ml-4 mr-4 shadow-[0_20px_50px_rgba(251,_85,_51,_0.7)] h-[auto] p-3 max-w-[250px] font-bold text-center">Mua hàng ngay</li>
          <li class="shadow-lg h-[auto] text-[18px] border rounded-md p-3 max-w-[250px] text-center font-bold ml-4">Xem sản phẩm</li>
         </ul>
       </div>
       <div class="flex  justify-center">
         <img class="max-h-[500px] shadow-lg rounded-lg animate-bounce-fast " src="https://ik.imagekit.io/39wvgoqre/THC%20/TH%208S/TH%208S/1_1.png?updatedAt=1702109195287" />
       </div>
     </div>
    </div> */}
    <Swiper
        
        autoplay={{
          delay: 6500,
          disableOnInteraction: false,
          speed: 2000, 
        }}
       
        modules={[Autoplay]}
        className="mySwiper h-[auto]"
      >
        {
          swiperData.map(data => (
            <SwiperSlide key={data.key}>
              <div class="grid md:grid-cols-2 grid-cols-1 max-w-[1200px] md:mt-[300px] mt-[200px] mb-[60px] md:mb-0  min-h-[80vh] ml-auto mr-auto " >
                <div class="flex items-ceter flex-col ">
                <h1 class="font-[900] text mb-2 sm:text-[35px] lg:text-[40px] text-[25px] mt-10 mb-2">{data.heading}</h1>
                <p class="mb-[40px] text-[18px] mt-[20px] font-semibold">{data.paragraph}</p>
               <ul class="flex rounded-lg mt-5 md:mb-0 mb-[50px]">
              <li class="bg-[#ee4d2d] text-[16px] text-white rounded-md md:ml-4 md:mr-4 ml-1 mr-1 shadow-[5px_5px_rgba(238,_77,_45,_0.4),_10px_10px_rgba(238,_77,_45,_0.3),_15px_15px_rgba(238,_77,_45,_0.2),_20px_20px_rgba(238,_77,_45,_0.1),_25px_25px_rgba(238,_77,_45,_0.05)] h-[auto] max-w-[250px] font-bold text-center flex justify-center"> <a href={data.Link_shoppe} class="p-3">Mua hàng ngay</a></li>
              <li class="shadow-lg h-[auto] text-[16px] border rounded-md max-w-[250px] text-center font-bold ml-4 flex justify-center"><a href={data.Link_shoppe} class="p-3">Xem sản phẩm</a></li>
             </ul>
           </div>
           <div class="flex  justify-center pr-[20px] ">
            <a href={data.Link_shoppe}><img class="max-h-[500px] shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(0,_98,_90,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)] rounded-lg animate-bounce-fast " src={data.img} /></a>  
           </div>
        
         </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
   </div>
  )
}

export default Slider;

