export const swiperData = [
    {
      "heading": "Bộ Lau Nhà Tự Vắt 360 Độ Inox THC-8S",
      "paragraph":"Sản phẩm Bộ Lau Nhà Tự Vắt 360 Độ Spin Mop 2 In 1 là một sản phẩm có thiết kế hiện đại, với thân cây được làm từ inox cao cấp, chắc chắn, bền bỉ. Mâm lau được làm từ inox chống rỉ sét, giúp lau sạch mọi vết bẩn trên sàn nhà",
      "img":"https://ik.imagekit.io/39wvgoqre/THC%20/TH%208S/TH%208S/1_1.png?updatedAt=1702109195287",
      "Link_shoppe":"https://shopee.vn/C%C3%A2y-Lau-Nh%C3%A0-T%E1%BB%B1-V%E1%BA%AFt-360-%C4%90%E1%BB%99-Inox-THC-8S-Super-Mop-2-In-1-B%E1%BB%99-Lau-Nh%C3%A0-360-%C4%90%E1%BB%99-T%E1%BB%B1-V%E1%BA%AFt-Th%C3%B4ng-Minh-Cao-C%E1%BA%A5p-%C4%90a-N%C4%83ng-THC-Cleaning-i.1071087389.21095431845",
      "link_slug":"https://www.tinhao.com.vn/product/Bo-Lau-Nha-Tu-Vat-360-DJo-Inox-THC-8S",
       "key":1,
    },
    {
      "heading": "Cây Lau Nhà Tự Vắt 360 Độ Inox THC-3S Spin Mop 2 In 1",
      "paragraph":"Sản phẩm Bộ Lau Nhà Tự Vắt 360 Độ Spin Mop 2 In 1 là một sản phẩm có thiết kế hiện đại, với thân cây được làm từ inox cao cấp, chắc chắn, bền bỉ. Mâm lau được làm từ inox chống rỉ sét, giúp lau sạch mọi vết bẩn trên sàn nhà.",
      "img":"https://ik.imagekit.io/39wvgoqre/THC%20/TH%203S/TH%203S/NEW%201_1.png?updatedAt=1702108613508",
      "Link_shoppe":"https://shopee.vn/C%C3%A2y-Lau-Nh%C3%A0-T%E1%BB%B1-V%E1%BA%AFt-360-%C4%90%E1%BB%99-Inox-THC-3S-Spin-Mop-2-In-1-B%E1%BB%99-Lau-Nh%C3%A0-360-%C4%90%E1%BB%99-T%E1%BB%B1-V%E1%BA%AFt-Th%C3%B4ng-Minh-Cao-C%E1%BA%A5p-%C4%90a-N%C4%83ng-THC-Cleaning-i.1071087389.22761438733",
      "link_slug":"https://www.tinhao.com.vn/product/Bo-Lau-Nha-Tu-Vat-360-DJo-Inox-THC-3S",
      "key":2
    },
    {
      "heading": "Cây Lau Nhà Tự Vắt 360 Độ Inox TH-4CI Penguin 2",
      "paragraph":"Sản phẩm Bộ Lau Nhà Tự Vắt 360 Độ Penguin 2 In 1 là một sản phẩm có thiết kế hiện đại, với thân cây được làm từ inox cao cấp, chắc chắn, bền bỉ. Mâm lau được làm từ inox chống rỉ sét, giúp lau sạch mọi vết bẩn trên sàn nhà.",
      "img":"https://ik.imagekit.io/39wvgoqre/THC%20/TH%204CI/1_1.png?updatedAt=1702118314679",
      "Link_shoppe":"https://shopee.vn/C%C3%A2y-Lau-Nh%C3%A0-T%E1%BB%B1-V%E1%BA%AFt-360-%C4%90%E1%BB%99-Inox-THC-136-Spin-Mop-2-In-1-B%E1%BB%99-Lau-Nh%C3%A0-360-%C4%90%E1%BB%99-T%E1%BB%B1-V%E1%BA%AFt-Th%C3%B4ng-Minh-Cao-C%E1%BA%A5p-%C4%90a-N%C4%83ng-THC-Cleaning-i.1071087389.25254125542",
      "link_slug":"https://www.tinhao.com.vn/product/Bo-Lau-Nha-360-DJo-Tu-Vat-Thong-Minh-Cao-Cap-DJa-Nang-THC-Cleaning",
      "key":3
    },
  ]