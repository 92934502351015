// import {IoIosCall} from 'react-icons/io'
import Menu from "../moblie/menuMoble.jsx"
import Cart from "../content/cartpopup.jsx"
import {AiOutlineMenu,AiOutlineShoppingCart,AiOutlineSearch,AiOutlineUser,AiFillCaretDown} from 'react-icons/ai'
import {MdLogout} from "react-icons/md" 
import {PiHandbagSimple} from "react-icons/pi"
import { resetCart } from "../../redux/action/apiRequest.js"
import {Link,useLocation,useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {AiOutlineClose } from "react-icons/ai"
import {useState,useEffect} from 'react';
import "../../style/animation.css"

const  Header = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [isOpen,setIsOpen]  = useState(false)
  const [isCart,setIsCart]  = useState(false)
  const [searchTerm, setSearchTerm] = useState('');
  const [headerName,setHeaderName] = useState('/')
  const location = useLocation();;
  const userName = useSelector((state) => state.root.auth.login.currentuser);
  const cart = useSelector((state) => state.product.cart);
  const cartQuantity = cart.cartItems.length;
      // Scroll to the top of the page when the route change
  useEffect(() => {
     window.scrollTo(0, 0);
  }, [location.pathname]);

  // serch product
  const history = useNavigate();
  const handleSearch = () => {
    history(`/search/${searchTerm}`);
  };
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
    // update later
    // const navigate = useNavigate();
  // update logout section later
  const dispatch = useDispatch();
  const handleLogout = () => {
     localStorage.removeItem("persist:root")
    dispatch(resetCart)
     window.location.reload();
  }
  const handleOpenMenu = () => {
     setIsMenu(true);
  }
  const handleCloseMenu = () => {
     setIsMenu(false)
  }
  const handleOpen = () => {
    setIsOpen(true)
    setIsCart(false)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleOpenCart = () => {
    setIsCart(true)
  }
  const handleCLoseCart = () => {
 
    setIsCart(false)
  }

  
  return (
    <>
     
      <div className="flex flex-col justify-between w-full bg-[#fff] h-[auto]  items-center shadow-[0_10px_20px_rgba(8,_112,_184,_0.7)] fixed top-0 z-30 ">
   
        <div class="flex w-full justify-between items-center p-3 max-w-[1200px] md:min-h-[80px] ">
            <div class="flex justify-center items-center ml-auto mr-auto max-w-[1200px] lg:hidden">
            <div class="flex jutify-center items-end">
             <img class="w-[100px] sm:w-[200px] mr-2 mt-[2px]" src="https://ik.imagekit.io/39wvgoqre/logo/370323579_6278720615590880_1703899035069007565_n.png?updatedAt=1699513666493" alt="" srcset="" />
             <h1 class="md:text-[18px] text-[12px]  font-bold ">CÔNG TY TNHH SX-TM-DV TÍN HÀO</h1>
       
          
             </div>
            </div>
             <div class="hidden lg:block">
             <img class="w-[150px] sm:w-[200px]  ml-[-5px] mt-[2px]" src="https://ik.imagekit.io/39wvgoqre/logo/370323579_6278720615590880_1703899035069007565_n.png?updatedAt=1699513666493" alt="" srcset="" />
             <h1 class="md:text-[18px] text-[12px]  font-bold ">CÔNG TY TNHH SX-TM-DV TÍN HÀO</h1>
         
          
             </div>
             <div class=" border-2 rounded-[20px] hidden mx-4 lg:flex  border-pur mr-3 py-1 items-center w-[400px] mt-1 p-2 ">
            <AiOutlineSearch class="text-[25px]  mr-2 ml-1" onClick={searchTerm.length !== 0 ? handleSearch : null}/>
            <input type="text" class="w-[80%]  outline-none " placeholder="Tìm kiếm sản phẩm"   onChange={handleSearchInputChange} onKeyPress={searchTerm.length !== 0 ? handleKeyPress : null}/>
            </div>
      
        </div>
        <div class="hidden lg:flex  w-full justify-between items-center mt-[-10px] max-w-[1200px] min-h-[auto] ">
        <ul class="flex text-[14px] justify-start mb-2 ml-1 w-full">
               <li class="mx-2 underline underline-offset-2"><span class="font-bold no-underline">Email:</span> thctinhao@gmail.com</li>
               <li class="mx-2 underline underline-offset-2"><a href="tel:028 39625428"><span class="font-bold no-underline">SĐT:</span> 028 39625428</a></li>
               <li class="mx-2 underline underline-offset-2"> <a href="https://zalo.me/0933627688"><span class="font-bold no-underline">Zalo:</span> 028 39625428</a></li>
             </ul>
        </div>

     
        <div class="w-full mr-auto ml-auto flex p-2 justify-center  bg-gradient-to-r from-pur to-darkpur shadow-[0_10px_12px_rgba(8,_112,_184,_0.7)] shadow-xl text-white">
        <div class="flex w-full justify-between items-center mt-3 mb-3 text-[16px]  max-w-[1200px] ">
           <ul class="flex justify-center items-center   cursor-pointer z-40">
           <li class="hidden mx-4 lg:flex"><Link to="/" className={location.pathname === '/' ? 'font-bold' : ''}>Trang chủ</Link></li>
 
            <li class="hidden mx-4 group lg:flex"><AiOutlineMenu  class="mt-1 mr-2 text-[26px] sm:text-[17px]  relative"/>
            <div class="absolute bg-white shadow-lg hidden group-hover:block top-[90%] w-[200px]">
              <ul class="text-[black]">
              <Link to="/product/category/bo-lau-nha"><li class="p-3 hover:bg-gradient-to-r from-pur to-darkpur hover:bg-pur hover:text-white duration-300 cursor-pointer"> Bộ lau nhà </li></Link> 
               <Link to="/product/category/cay-lau-nha"><li class="p-3 hover:bg-gradient-to-r from-pur to-darkpur hover:text-white duration-300 cursor-pointer">Cây lau nhà </li></Link> 
               <Link to="/product/category/mut"><li class="p-3 hover:bg-gradient-to-r from-pur to-darkpur hover:text-white duration-300 cursor-pointer">Mút </li></Link> 
               <Link to="/product/category/phu-kien"><li class="p-3 hover:bg-gradient-to-r from-pur to-darkpur hover:text-white duration-300 cursor-pointer">Phụ Kiện </li></Link> 
              </ul>
            </div>
            <p>Danh mục sản phẩm</p>
             
            </li>
            <li class="hidden mx-4 lg:flex"><Link to="/contact" className={location.pathname === '/contact' ? ' font-bold' : ''}>Liên hệ</Link></li>
            <li class="hidden mx-4 lg:flex"><Link to="/aboutUs" className={location.pathname === '/aboutUs' ? ' font-bold' : ''}>Về chúng tôi</Link></li>
            <li class="flex lg:hidden mx-4 text-left ml-[-2px]" onClick={handleOpenMenu}><AiOutlineMenu   class="mt-1 mr-2 text-[26px] "/></li>
          </ul>
          <div class=" border-2 rounded-[20px] flex  lg:hidden bg-white border-white  py-1 items-center w-[400px] mt-1 p-2 ml-[-12px]">
            <AiOutlineSearch class="text-[25px] text-pur  mr-2 ml-1" onClick={searchTerm.length !== 0 ? handleSearch : null}/>
            <input type="text" class="w-[80%] text-[#000] bg-none outline-none " placeholder="Tìm kiếm sản phẩm"   onChange={handleSearchInputChange} onKeyPress={searchTerm.length !== 0 ? handleKeyPress : null}/>
          </div>
         <div class="flex cursor-pointer mr-1 ml-[6px] relative" onClick={handleOpenCart}>
          <p class="font-bold hidden sm:block">Lưu sản phẩm</p>
          {cartQuantity !== 0 ? <p class="absolute top-[-40%] 	animate-bounce right-[-10%] bg-[#ee3137] border-2 p-2 border-[rgb(238,49,55)] shadow-lg  w-[20px] h-[20px] rounded-full flex justify-center items-center " >{cartQuantity}</p> : ""}
          
       
         <PiHandbagSimple class=" text-[28px] sm:text-[24px] mt-[0.5px] ml-2 cursor-pointer text-white"/>
    
       
         </div>
        
         
    
        </div>
        </div>
      
      </div>
       {isMenu 
       ?( 
       <div>
           <Menu animationMenu={'animation-open-menu'}/>
           <div class="fixed h-[100%] w-[30%] inset-y-0 right-0 cursor-pointer z-50" onClick={handleCloseMenu}></div>
          
         </div>
       ) : (
         <div>
           <Menu animationMenu={'animation-close-menu'}/>
         </div>
       )}
           {
            isCart ? (<div class=" z-50 fixed top-0 right-0 h-full ">

            <Cart animationClass={'animation-open'} />
            <div class="fixed bg-[#0004] h-[100%] w-[100%] inset-y-0 right-0 cursor-pointer z-40 opacity-1" onClick={handleCLoseCart}></div>
            <div class="absolute top-0 right-0 p-3 text-[22px] cursor-pointer cart-visible z-50" onClick={handleCLoseCart}>
                <AiOutlineClose />
            </div>
           </div> ) :   
             (<div class=" z-50 fixed top-0 right-0 h-full ">
            {/* <div class="fixed bg-[#0004] h-[100%] w-[100%] inset-y-0 right-0 cursor-pointer z-40 opacity-0" ></div> */}
            
            <Cart animationClass={'animation-close'} />
            <div class="absolute top-0 right-0 p-3 text-[22px] cursor-pointer animation-close z-50" onClick={handleCLoseCart}>
                <AiOutlineClose />
            </div>
           </div> ) 
          
            
          } 
     </>
      
  )
  
  
}


export default Header;