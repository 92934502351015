import { useParams,Link ,useNavigate} from 'react-router-dom';
import {useDispatch,useSelector} from "react-redux"
import {getProductDetail as fetchDetailProduct,addToCart} from "../../redux/action/apiRequest"
import Related from '../../component/detailProduct/related.jsx'
import { useState, useEffect } from 'react';
import {AiOutlinePlus,AiOutlineMinus} from "react-icons/ai"
import { IoIosArrowForward } from "react-icons/io";
import {MdArrowBackIos} from "react-icons/md"
import ImageGallery from "react-image-gallery";
import  "react-image-gallery/styles/css/image-gallery.css";
import "./checkmark.css"

const Detail = () => {
  // get slug by using params
    const { slug } = useParams();
    const dispatch = useDispatch();
  
  // setting number 
    const [isShowCart,setIsShowCart] = useState(false)
      // Set display close and open
    const [isShown, setIsShown] = useState(false);
    const [colors,setColor] = useState('');
 
   // get single product 
   const getProductDetail = useSelector((state) => state.product);
   const item = getProductDetail.item;

  //  get cart

   
    // check user 
    function display () {
      setIsShown(true)
    }
    function close () {
      setIsShown(false)
    }
    
  
 
  //  update value
    // fetch and get data
    useEffect(() => {
      dispatch(fetchDetailProduct(slug));
    }, [dispatch,slug]);
  
   
    const handleAddToCart = () => {

         dispatch(addToCart(slug));
         setIsShowCart(true)
     
    }

    const closeNofti = () => {
       setIsShowCart(false)
    }

      const main_img = [
        {
          original:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdo4PV6eyYlgYAHT8n5USiwyhtJwuZ_0tbgJVnKVBivSlZuZLt6xfkWsFzPQLwMQ_k42g&usqp=CAU",
          thumbnail:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdo4PV6eyYlgYAHT8n5USiwyhtJwuZ_0tbgJVnKVBivSlZuZLt6xfkWsFzPQLwMQ_k42g&usqp=CAU",
        },

        // ... other images ...
      ];
      const images = item.product?.photo_pageDetail.map(img => ({original:img,thumbnail:img})) || [];
      const sub_img = images.concat(main_img)
      const navigate = useNavigate();
   
  // zoom out image gallery
  function Modal(){
         
         return (
          <div class="h-[100vh] fixed z-30 top-0 left-0 right-0 bottom-0 bg-[#0004] flex justify-center items-center"  >
            <div class="max-w-[1200px]   p-10 rounded">
            <ImageGallery 
             items={sub_img} 
             slideDuration={550}
             swipingTransitionDuration={300}
             slideOnThumbnailOver={true}
             showPlayButton={false}
             showNav={false}
             showFullscreenButton={false}
             thumbnailPosition={"right"}
             onClick={(e) => {
               e.preventDefault();
               close()
             }}
          
            />
            </div>
          </div> 
         )
   }

  function ShowCartNofti(){
     return (
       <div class="fixed top-[25%] right-[0%]  z-50 w-[400px] h-auto flex justify-end items-end">
           <div id="toast-success" class="flex mt-10 items-center w-full max-w-xs p-4 mb-4 text-pur bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
    <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span class="sr-only">Check icon</span>
    </div>
    <div class="ml-3 text-sm font-normal">Thêm sản phảm vào thành công.</div>
    <button type="button" onClick={closeNofti} class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
        <span class="sr-only" onClick={closeNofti}>Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"  >
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
    </button>
</div>

       </div>
     )
  }
   return (

     <div class="mt-[230px] p-3 max-w-[1200px] ml-auto mr-auto  h-[auto]">
        <div class="font-bold mb-2">
     <p class="mt-5 flex"><Link to="/">Trang chủ </Link> <IoIosArrowForward class="mt-1.5" /> <Link to={`/product/category/${item.product?.category}`}>{item.product?.category_name}</Link> <IoIosArrowForward class="mt-1.5" /> {item.product?.name} </p>
     </div>
       <div class="flex flex-col lg:flex-row w-full items-center">
         <div class=" w-[100%] lg:w-[50%] ">
          <div class="mt-2 max-w-[550px] z-20 ml-auto mr-auto lg:ml-0 lg:mr-0">
          <ImageGallery 
             items={sub_img} 
             slideDuration={550}
             swipingTransitionDuration={300}
             showPlayButton={false}
             showNav={false}
             showFullscreenButton={false}
             thumbnailPosition={"left"}
             onClick={(e) => {
               e.preventDefault();
               display()
             }}
            />
         
          
          </div>
         </div>
         <div class="  w-[100%] lg:w-[50%]   p-1 ml-0 lg:ml-5">
          {/* {
            item ? (<p class="font-bold mb-2 ml-[-4px] mt-5  text-[32px]">{formatPrice(item.product?.price)}<span class="underline">đ</span></p>) : (<h1>Loading</h1>)
          } */}
  
          <h1 class="font-sm leading-7 text-[25px] ">{item.product?.name}</h1>
          <p class=" mt-3 ">Sản phẩm Cây Lau Nhà Mút Mềm Mỹ của THC CLEANING không chỉ là công cụ hiệu quả cho việc làm sạch nhà cửa mà còn là sự kết hợp hoàn hảo giữa tính tiện lợi và khả năng làm sạch hiệu quả. Với những ưu điểm nổi bật sau</p>
         
          <div class={`flex   border-b-2  border-dotted py-4`}>
            <div class="w-full">
              <ul >
                <li class="mb-5 flex w-full justify-between">
                  <p class="font-bold">Mã hàng</p>
                 <p>{item.product?.description}</p></li>
           
                
           
                <li class="mt-5 flex w-full justify-between">
                  <p class="font-bold">Chất liệu</p>
                  <p class=" text-right indent-10">
                  {item.product?.materials}
                  </p>
             </li>
                <li class="mt-5 flex w-full justify-between items-center">
                  <p class="font-bold">Nhãn hiệu</p>
                  <p>THC CLEANING</p></li>
                  <li class="mt-5 flex w-full justify-between">
                  <p class="font-bold flex justify-center items-center">Thêm vào mục</p>
                  <p class={`border-2 border-[#000] p-2 cursor-pointer ${isShowCart ? "bg-[#000] text-white" : ""} `} onClick={handleAddToCart}>Lưu sản phẩm</p></li>
              </ul>
            </div>
         
         </div>
          <div> 
          
            {/* <div class={` grid grid-cols-1 sm:grid-cols-2 ${setError ? "bg-[#fff5f5]" : "bg-[#f2f2f2] text-[#000]"} w-full `}>
               <div>
                <p class="mt-2 mb-2 font-bold">Số Lượng</p>
               <div class="flex justify-between items-center p-1 border w-[200px] z-20">
               <AiOutlineMinus onClick={setDecrease} class="cursor-pointer font-bold z-20 text-[25px]"/>
                <p class="font-bold text-[18px]">{amount}</p>
                <AiOutlinePlus onClick={setIncrease} class="cursor-pointer font-bold z-20 text-[25px]"/>
               </div>
              </div>
                <div class="w-full ">
                {
                  item.product?.colors.length  !== 0 ? (
                    <div class="max-w-[500px] ">
          
                    <p class="mt-2 mb-2 font-bold text-left sm:text-right">Phân loại</p>
                    <div class="flex justify-start sm:justify-end">
                    {
                    item.product?.colors.map((color, index) => (
                       <li
                         key={index}
                         className={`  px-5 ml-0 sm:ml-2 mr-2 sm:mr-0 h-[40px]  border-2 flex justify-center cursor-pointer items-center ${colors === color ? 'bg-gradient-to-r border-2 from-pur to-darkpur text-white' : ''}`}
                         onClick={() => handleColor(color)}
                        >
                      {color}
                     </li>
                    ))}
                    </div>
                 
                    </div>
                    
                  ) : ""
                }
                </div>
                {setError ? <p class="w-full font-bold text-[#ed4337] text-center p-2 col-span-2 mt-2">Vui lòng chọn sản phẩm</p> : ""}
            </div> */}
        
  
            <div class="mt-4 flex flex-col sm:flex-row ">
              <div class="font-bold w-[100%] sm:w-[50%] z-20">
               <a href="https://zalo.me/0933627688" ><p class="p-2 border-2 bg-pur border-pur duration-300 cursor-pointer  text-white hover:bg-white hover:text-[#000] w-[full] text-center " >Liên hệ zalo</p></a> 
              </div>
              <div class="font-bold w-[100%] sm:w-[50%] z-20 ml-0 mt-4 sm:ml-5 sm:mt-0 " >
               <a href={`${item.product?.link_shoppe}`}><p class="p-2  border-2 bg-[#ec4c2c] border-[#ec4c2c] text-white    w-[100%] text-center cursor-pointer hover:bg-[#fff] hover:text-[#000] duration-300 ">Xem shoppe </p> </a>
              </div>
            </div>
          </div>
         </div>
 
       </div>
     
     
       <div class="mt-10">

  
        <p class="font-bold text-[20px] mt-2 mb-2">Cam Kết Về Thương Hiệu THC - Sản Xuất Đồ Gia Dụng</p>
         <ul class="list-disc">
            <li class="ml-5">Với hơn một thập kỷ kinh nghiệm trong lĩnh vực này, ĐẢM BẢO SẢN PHẨM CHẤT LƯỢNG 100</li>
            <li class="ml-5">Chúng tôi luôn lắng nghe ý kiến của khách hàng để mang đến cho bạn những sản phẩm tốt nhất.</li>
            <li class="ml-5">Mặt hàng có sẵn, giao hàng ngay sau khi nhận được đơn đặt hàng.</li>
            <li class="ml-5">Sản phẩm được kiểm tra kĩ càng, cẩn thận đóng gói hàng trước khi giao.</li>
            <li class="ml-5">Nếu gặp lỗi từ nhà sản xuất, chúng tôi hỗ trợ đổi trả 7 ngày theo quy định tính từ lúc đơn hàng được cập nhật giao hàng thành công.</li>
         </ul>
         <p class="font-bold text-[20px] mt-2 mb-2">Liên Hệ Với Chúng Tôi</p>
          <ul>
            <li>Hãy liên hệ với chúng tôi qua HOTLINE 0839625428 hoặc qua email tinhaomini@gmail nếu bạn có bất kỳ câu hỏi, ý kiến hoặc yêu cầu đặc biệt nào. Chúng tôi luôn sẵn sàng lắng nghe và hỗ trợ bạn một cách nhiệt tình.</li>
            <li>Hãy khám phá thế giới sản phẩm đa dạng và đẳng cấp tại THC ngay hôm nay và biến việc làm sạch nhà cửa thành một trải nghiệm thú vị và đầy phong cách!.</li>
          </ul>
          <p class="mt-5 font-bold">Cảm ơn quý khách đã tin tưởng và mua sản phẩm, ấn Follow shop để lấy mã giảm giá và được chương trình khuyến mãi của THC sớm nhất mọi người nhé !!!</p>
      </div>
      <Related category={item.product?.category}/>
     
      {isShown ? <Modal /> : null }
      {isShowCart ? 
      (
      <div>
        <ShowCartNofti /> 
        <div class="fixed inset-0 z-[10] " onClick={closeNofti}>
        </div>
      </div>

      
      ): null}
    </div>
    
      )
     
}

export default Detail


