import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  RESET_CART
} from '../constant/cartConstant'
import { createSlice } from "@reduxjs/toolkit";

const CART_INITIAL_STATE = {
  cartItems: [],
};
export const getProdutCart = (state = CART_INITIAL_STATE ,action) => {
    switch (action.type){
      case ADD_TO_CART:
        const item = action.payload
        const exsitItem = state.cartItems.find((x) => x.name === item.name)
   
        if (exsitItem){
          return {
            ...state,
            cartItems: state.cartItems.map((x) => 
               x.name === exsitItem.name ? item : x
            )
          }
        }else {
           return {
            ...state,
            cartItems:[...state.cartItems,item],
            exsitItem:null
           }
        }
      case REMOVE_FROM_CART:
        return {
          ...state,
          cartItems: state.cartItems.filter((x) => x.slug !== action.payload)
        }
      case RESET_CART:
        return {
           ...state,
           cartItems:[]
        }
      default:
        return state
    }
}
