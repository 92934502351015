import {AiOutlineMessage} from "react-icons/ai"
import {SiShopee} from "react-icons/si"
import { FaFacebookF } from "react-icons/fa";
import { useState } from "react"
const Socialbox = () => {
    const [check,setCheck] = useState(false)
    const handleCheck = () => {
        setCheck(!check)
    }
    return (
    <div class="fixed bottom-0 right-2 z-50">
        <div class="fixed left-5 bottom-2 bg-white rounded-full animate-bounce">
           <a href="https://zalo.me/0933627688"> <img src="https://ik.imagekit.io/39wvgoqre/logo/zalo.png?updatedAt=1699624297978" alt="zalo"  class="w-[60px] "/></a> 
        </div>
        <div class="fixed right-0 bottom-[15%]  rounded-full">
            <div   class="w-[40px] h-[40px] flex justify-center items-center bg-[#0866ff] border border-[#0866ff]  shadow-lg cursor-pointer hover:bg-[#100c78] hover:translate-y-[-5px] duration-300">
           <a href="https://www.facebook.com/profile.php?id=61558501585947" class="text-white font-bold p-3"><FaFacebookF /></a>  
            </div>
            <div class="w-[40px] h-[40px] mt-2 flex justify-center items-center  bg-[#ee4d2d] cursor-pointer hover:bg-[#780c10] hover:translate-y-[-5px] duration-300 ">
             <a href="https://shopee.vn/thc_cleaning" class="text-white font-bold"> <SiShopee /></a>
            </div>
        </div>
    
   </div>
    )

}

export default Socialbox;