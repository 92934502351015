import {useState,useEffect} from 'react'
import {Link,useParams} from 'react-router-dom'
import axios from 'axios'
const ApiTHC = "https://test-api-bhc7.onrender.com";
const  ChoiProduct = () => {
  const [products,setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("cay-360");
  useEffect(() => {
    // Fetch product data ofrm your API endpoint
    axios.get(`${ApiTHC}/api/products/filter/${selectedCategory}`)
      .then((response) => setProducts(response.data.products)) // Assuming the product data is under the "products" key
      .catch((error) => console.error('Error fetching products:', error));
  }, [selectedCategory]);
  const handleCategoryClick = (category) => {
    setSelectedCategory(category)
 };
    return (
      <div class="max-w-[1200px] ml-auto mr-auto">
     
       <div class="grid md:grid-cols-2 gap-10 mb-4 grid-cols-1 p-2">
       <div class="overflow-hidden group md:mr-[0px] mr-[15px] cursor-pointer  relative p-2  shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(48,_81,_161,_0.1),_25px_25px_rgba(0,_98,_90,_0.05)]">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine shadow-lg" />
         <img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/BANNER%20NEW.png?updatedAt=1701931966333" alt="" srcset="" />
       </div>
       <div class="overflow-hidden group cursor-pointer md:mr-[0px] mr-[15px]  p-2 relative shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(48,_81,_161,_0.1),_25px_25px_rgba(0,_98,_90,_0.05)]">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
         <img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/TH%2016.png?updatedAt=1701931965030" alt="" srcset="" />
       </div>
     </div>
   
     <div class="flex flex-col md:flex-row md:justify-between justify-center items-center  p-2">
       <h1 class="font-bold md:text-[24px] text-[20px]  mt-[20px]">Sản phẩm Cây Lau Nhà</h1>
       <ul class="flex md:mt-[10px] mt-[20px] text-[18px]  font-bold">
       <li className={`${  selectedCategory === 'cay-360' ? 'bg-gradient-to-r from-pur to-darkpur text-white' : ''} mr-2 p-2 px-4 cursor-pointer border-2`} onClick={() => handleCategoryClick("cay-360")}>Cây lau nhà 360</li>
             <li className={`${  selectedCategory === 'cay-tu-vat' ? 'bg-gradient-to-r from-pur to-darkpur text-white' : ''} ml-2 p-2 cursor-pointer border-2  `} onClick={() => handleCategoryClick("cay-tu-vat")}>Cây lau Nhà Tự Vắt </li>
       </ul>
     </div>
            <div class="grid grid-cols-2 lg:grid-cols-5 sm:grid-cols-3 gap-4 mb-4 mt-5 px-3">
            {products.map((product) => (
            
            <div key={product._id} className="flex border border-[#0004] ">
            <Link to={`/product/${product.slug}`} class="">
             <div class="group oveflow-hidden min-h-[380px]   shadow-lg relative overflow-hidden cursor-pointer duration-300">
             <div className="max-w-[350px] flex items-center relative">
             <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-[1] group-hover:animate-shine" />
            <img
              src={product.photo}
              className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-100 group-hover:opacity-0 transition-opacity duration-300 mt-5 "
              alt=""
            />
           <img
             src={product.sub_photo}
             className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 left-[50%] translate-x-[-50%] mt-5"
             alt=""
            />
          </div>
             
              <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
                 <div class="p-2">
           
                 <h1 class="font-bold mb-0  group-hover:text-pur duration-300 mt-3">{product.name }</h1>
                  <div class=" font-medium flex justify-between item-end absolute bottom-0 left-0 right-0 px-2 pb-3 ">
                  <p>TPHCM</p>
                  <p> {product.description }</p>
                  </div>
                 </div>
             </div>
             </Link>
            </div>
          ))} 
            
       
           </div>
           <div class=" grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 md:mt-[30px] mt-[20px] px-2">
           <div class="ml-2 flex flex-col justify-center items-center">
             <div class="md:mt-2 mt-5">
              <h2 class="text-[18px] font-bold mb-2">Chổi Lau Nhà Chất Lượng, Trải Nghiệm Độc Đáo</h2>
              <p>- Khám phá bộ sưu tập chổi lau nhà của chúng tôi với chất lượng vượt trội và thiết kế độc đáo. Đưa vào công việc lau dọn sự hiệu quả và thuận tiện.</p>
             </div>
             <div class="md:mt-2 mt-5 ">
              <h2 class="text-[18px] font-bold mb-2">Lựa Chọn Số 1 Cho Việc Lau Nhà</h2>
              <p>- Tận hưởng sự đa dạng và chất lượng với chổi lau nhà của chúng tôi. Sản phẩm thiết kế để đáp ứng mọi nhu cầu lau dọn của bạn.</p>
             </div>
             <div class="md:mt-2 mt-5 ">
              <h2 class="text-[18px] font-bold mb-2">Mua Chổi Lau Nhà, Nhận Ngay Trải Nghiệm Hoàn Hảo</h2>
              <p>- Chúng tôi tự tin mang đến cho bạn trải nghiệm mua sắm chất lượng nhất với chổi lau nhà chuyên nghiệp và đa dạng. Đặt hàng ngay để trải nghiệm sự tiện lợi và hiệu suất.</p>
             </div>
           </div>
           <div>
           <div class="overflow-hidden p-2 pr-4 mr-[20px]  group cursor-pointer relative shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(0,_98,_90,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)]">
            <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
            <a href="https://shopee.vn/product/1071087389/22184052800"><img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/TH%2016.png?updatedAt=1701931965030" alt="" srcset="" /></a> 
           </div>
           </div>
           
        </div>
        <div class="md:grid grid-cols-3 px-3 mb-5 hidden gap-8 mt-4 ">
       <div class="overflow-hidden group cursor-pointer relative shadow-[5px_5px_rgba(0,_98,_90,_0.4),_10px_10px_rgba(0,_98,_90,_0.3),_15px_15px_rgba(0,_98,_90,_0.2),_20px_20px_rgba(0,_98,_90,_0.1),_25px_25px_rgba(0,_98,_90,_0.05)]">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine " />
        <a href="https://shopee.vn/product/1071087389/19393267595"> <img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/TH%20108.png?updatedAt=1701931963458" alt="" srcset="" /></a>
       </div>
       <div class="overflow-hidden group cursor-pointer relative shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(0,_98,_90,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)]">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
        <a href="https://shopee.vn/product/1071087389/24908676029"><img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/TH%201283.png?updatedAt=1701931963493" alt="" srcset="" /></a> 
       </div>
       <div class="overflow-hidden group cursor-pointer relative shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(0,_98,_90,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)]">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
        <a href="https://shopee.vn/product/1071087389/21884274470"><img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/TH%20166.png?updatedAt=1701931963262" alt="" srcset="" /></a> 
       </div>
      </div>
           </div>
    )
}


export default ChoiProduct;