const Content = () => {
    return (
        <div class=" p-2  bg-gradient-to-r from-pur to-darkpur">
           
           <div class="max-w-[1200px] pt-[20px] pb-[20px] flex items-center min-h-[500px]  ml-auto mr-auto p-2 mt-5">
           <div class="text-white">
            <h1 class="text-[22px] font-extrabold text-center mt-2  text-center">Lý do quý khách chọn chúng tôi </h1>
            <p class="mt-5 mb-5 md:text-center text-[16px] text-left mb-2 opacity-0.5">Chúng tôi là đơn vị chuyên phân phối hàng đầu trong lĩnh vực cung cấp các sản phẩm chất lượng cao như cây lâu nhà, bộ lau nhà và chổi lau. Với hơn 10 năm kinh nghiệm, chúng tôi tự hào là đối tác tin cậy của nhiều gia đình và doanh nghiệp trên khắp địa bàn.</p>

             <div class="grid grid-cols-1 md:grid-cols-3 mb-5 gap-10 text-[#000]">
               <div class="bg-white rounded-lg text-left shadow-lg border-[#fff] shadow-[0_10px_20px_rgba(250,0,0,_0.7)] p-3 rounded h-[220px] flex flex-col items-center justify-center border-2 sm:h-[250px] min-h-[250px]">
                <h2 class="text-[18px] text-center font-bold mb-2 p-2">Sự Đa Dạng và Độ Tin Cậy</h2>
                <p class="p-2">Bộ sưu tập sản phẩm của chúng tôi đa dạng và phong phú, từ những chiếc cây lâu nhà hiện đại đến bộ lau nhà đa chức năng và chổi lau thiết kế đẹp mắt.</p>
               </div>
               <div class="bg-white rounded-lg  text-left shadow-lg p-3 border-[#fff] shadow-[0_10px_20px_rgba(250,0,0,_0.7)] rounded-h-[520px] flex flex-col items-center justify-center border-2 sm:h-[250px] min-h-[250px]">
               <h2 class="text-[18px] p-2 text-center font-bold mb-2" >Đặt Chất Lượng Lên Hàng Đầu</h2>
             <p class="p-2">Với mỗi sản phẩm, chúng tôi đặt chất lượng lên hàng đầu. Tất cả sản phẩm của chúng tôi đều được chọn lọc kỹ lưỡng để đảm bảo sự bền bỉ .</p>
               </div>
               <div class="bg-white rounded-lg  text-left shadow-lg  p-3 border-[#fff] shadow-[0_10px_20px_rgba(250,0,0,_0.7)] rounded h-[220px] flex flex-col items-center justify-center border-2 sm:h-[250px] min-h-[250px]">
               <h2 class="text-[18px] p-2 text-center font-bold mb-2">Dịch Vụ Chăm Sóc Khách Hàng</h2>
                <p class="p-2">Đội ngũ chăm sóc khách hàng của chúng tôi luôn sẵn sàng hỗ trợ bạn mọi lúc, từ việc tư vấn chọn sản phẩm đến giải đáp mọi thắc mắc sau khi mua hàng.</p>
               </div>
             </div>
            </div> 
           </div>
        </div>
    )
}

export default Content;