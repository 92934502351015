import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducer/authSlice";
import {getProdutCart} from "./reducer/cartSlice"
import {getProductReducer,getProductDetail } from "./reducer/productSlice"
import { orderReducer } from "./reducer/orderSlice";
import { combineReducers } from '@reduxjs/toolkit';
import { applyMiddleware} from "redux"
import { composeWithDevTools } from "redux-devtools-extension";
  import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";
const middleware = [thunk];
const persistConfig = {
    key: 'root',
    storage,
  }
const productConfig = {
   key:'value',
   storage
}
// store cart in localStorage
const cartItemsInLocalStorage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

const INITIAL_STATE = {
  cart: {
    cartItems: cartItemsInLocalStorage,
  },
};
  const rootReducer = combineReducers({
    auth:authReducer,
    order: orderReducer,
  })
  const procductReducer = combineReducers({
    cart:getProdutCart,
    items:getProductReducer,
    item:getProductDetail,

    // cart:cartReducer
  })
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const persistedProductReducer = persistReducer(productConfig,procductReducer)
  const addThunk = composeWithDevTools(applyMiddleware(...middleware))
export const store = configureStore({
    reducer: {
      root:persistedReducer,
      product:persistedProductReducer,
    }, 
    INITIAL_STATE,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, PAUSE, PURGE, REHYDRATE, REGISTER, PERSIST],
        },
      }), 
    
    addThunk:addThunk,
    })

export const  persistor = persistStore(store);


