import {LiaStoreAltSolid} from 'react-icons/lia'
import {FiMessageSquare} from "react-icons/fi"
import {AiOutlineMail} from "react-icons/ai"
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <footer class="bg-white shadow-lg h-[100%] text-[#000] mt-[50px] font-bold">
          <div class="max-w-[1200px]   px-4 py-16 mx-auto sm:px-3 lg:px-3">
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
              <div>
              
              <div class="mt-[-40px]">
             <img class="w-[150px] sm:w-[200px]  ml-[-5px] mt-[2px]" src="https://ik.imagekit.io/39wvgoqre/logo/370323579_6278720615590880_1703899035069007565_n.png?updatedAt=1699513666493" alt="" srcset="" />
             <h1 class="text-[18px] font-bold ">CÔNG TY TNHH SX-TM-DV TÍN HÀO</h1>
             </div>
                <div class="flex mt-5">
                  <div class="border p-1 w-[30px] h-[30px] flex bg-pur rounded-[50%] justify-center items-center">
                  <LiaStoreAltSolid class="mt-1 text-white text-[18px]"/>
                  </div>
                  <div>
                     <p class="text-[15px] px-2 mt-1 font-medium"><span class="font-bold">Hồ CHí Minh:</span> Số 124, Hậu Giang, Quận 6</p>
                     <p class="px-2 mt-[-3px] cursor-pointer underline decoration-pur underline-offset-2 text-[14px] text-pur"><a href="https://goo.gl/maps/8xrDAJQtMQAKGQNr8 ">Xem bản đồ</a></p>
                  </div>
                </div>
                <div class="flex mt-5">
                  <div class="border p-1 w-[30px] h-[30px] flex bg-pur rounded-[50%] justify-center items-center">
                  <AiOutlineMail class="mt-1 text-white text-[18px]"/>
                  </div>
                  <div>
                     <p class="text-[15px] px-2 mt-1 font-medium"><span class="font-bold">Email:</span> thctinhao@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-3">
                <div>
                  <p class="font-bold">
                  Thông tin khác
                  </p>
                  <ul class="flex flex-col mt-4 space-y-2 text-sm text-gray-500 list-disc cursor-pointer">
                  <li class="ml-4 font-medium hover:translate-x-1 duration-300 cursor-pointer"> Giới thiệu THC CLEANING</li>
                   <li class="ml-4 font-medium hover:translate-x-1 duration-300 cursor-pointer"> <Link to="/contact">Liên hệ  </Link></li> 
            
                  </ul>
                </div>
                <div>
                  <p class="font-bold">
                    Trợ giúp
                  </p>
                  <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                      <div class="flex">
                         <FiMessageSquare class="mt-2  text-[22px] text-pur"/>
                         <div class="px-2"> 
                          <p>Hỗ trợ tư vấn: </p>
                          <p className='font-medium'>0283-9625-428</p>
                         </div>
                      </div>
                  </nav>
                </div>
                <div>
                  <p class="font-bold">
                    Những đường Links khác
                  </p>
                  <nav class="flex flex-col mt-4 space-y-1 text-sm text-gray-500">
                    <a class="hover:opacity-75 font-medium" href> FAQs </a>
                    <a class="hover:opacity-75 font-medium" href> Chat trực tiếp </a>
                  </nav>
                </div>
              </div>
            </div>
            <p class="mt-8 text-xs text-gray-800 font-medium">
              © 2023 Tín Hào Cleaning
            </p>
          </div>
        </footer>
    )
}

export default Footer;