import {AiOutlineMail,AiOutlinePhone} from "react-icons/ai"
import {MdOutlineLocationOn} from "react-icons/md";
import { useEffect,useState, useRef} from "react";
import axios from "axios";

const thcCleaningApi = `https://test-api-bhc7.onrender.com/api/email`;
const Contact = () => {
   const [userName,setUserName] = useState('');
   const [phoneNumber,setPhoneNumber] = useState('');
   const [email, setEmail] = useState('');
   const [information,setInformation] = useState('');
   
  //  seting ref input
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);


  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      if (nextInputRef.current) {
        nextInputRef.current.focus(); // Set focus to the next input
      }
    }
  };
   useEffect(() => {
      // Function to send cookies to the server
      async function sendCookies() {
        try {
          await axios.get('http://127.0.0.1:8080/set-cookie');
          console.log('Cookies sent successfully.');
        } catch (error) {
          console.error('Error sending cookies:', error);
        }
      }
  
      // Call the sendCookies function when the component mounts
      sendCookies();
    }, []);
    const handleSubmit = (e) => {
      e.preventDefault();
      window.location.reload();
      const data = {
        userName: userName,
        phoneNumber:phoneNumber,
        nameEmail: email,
        information: information,
      };
    
      axios.post(thcCleaningApi, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    })};
    return (
        <div class="md:mt-[300px] mt-[200px] mr-auto ml-auto min-h-[auto] max-w-[1200px] p-2">
          <div class="grid grid-cols-1 h-[auto] p-2 md:grid-cols-2">
             <div class="pr-4 md:mb-0 mb-10 md:text-left text-center"> 
                <h1 class="font-extrabold text-[25px]  text-[#033b88] mb-2">XIN CHÀO QUÝ KHÁCH!!!</h1>
                <p class="font-bold text-[20px]">Chào mừng bạn đến với thế giới gia dụng của THC Cleaning! Kết nối với chúng tôi để nhận ưu đãi giảm giá cho lần mua hàng đầu tiên</p>
                 <div class="h-[2px] mt-5 max-w-[250px] bg-[#033b88] shadow-lg md:ml-0 md:mr-0 ml-auto mr-auto"></div>
                <div class=" col-span-1"> 
                 <div class="flex mt-[16px]">
                  <div class="  flex  ">
                  <MdOutlineLocationOn class="mt-1 text-[#033b88]  text-[22px] "/>
                  </div>
                  <div class="ml-2 mt-1">
                     <p class="font-bold">124 Hậu Giang, phường 6, quận 6</p>
                  </div>
                </div>
                <div class="flex mt-[16px]">
                  <div class="  flex  ">
                  <AiOutlineMail class="mt-1 text-[#033b88]  text-[22px] "/>
                  </div>
                  <div class="ml-2 mt-1">
                     <p class="font-bold">thctinhao@gmail.com</p>
                  </div>
                </div>
                <div class="flex mt-[16px]">
                  <div class="  flex  ">
                  <AiOutlinePhone class="mt-1 text-[#033b88] text-[22px] "/>
                  </div>
                  <div class="ml-2 mt-1">
                     <p class="font-bold">0283-9625-428</p>
                  </div>
                </div>
            
                <div>
                   <h2 class="font-bold mb-2 mt-5 text-[20px]">Ghé thăm chúng tôi tại <a href="https://shopee.vn/thc_cleaning" class="cursor-pointer underline underline-offset-4">Shopee</a></h2>
                    <img class="max-w-[250px] rounded-lg md:ml-0 md:mr-0 ml-auto mr-auto mt-5 shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(0,_98,_90,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)]" src="https://ik.imagekit.io/39wvgoqre/THC%20/QR_shopee.png?updatedAt=1716629157257" alt="shopee_url" />
                </div>
             </div>
             </div>
             <div class="min-h-[700px] bg-[#fff] ml-1 mr-1 border border-[#fff] shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(48,_81,_161,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)] rounded-lg">
             <div class="p-2 col-span-2">
               <h1 class="font-extrabold  text-[25px] pt-3 pb-3 mt-10 md:text-center text-left   text-[#033b88] ">Liên hệ chúng tôi </h1>    
               <form class=" h-[100%] mt-5  justify-between pb-5" onSubmit={handleSubmit} action="#"  method="POST">
                  <div class="w-[100%] p-1  ">
                    <input  
                    class="w-full p-2 shadow-lg border"
                    placeholder="Họ và tên"
                    value={userName} onChange={(e) => setUserName(e.target.value)}
                    ref={input1Ref}
                    onKeyPress={(event) => handleKeyPress(event, input2Ref)}
                    />
                    <input  
                    class="w-full p-2 mt-4 shadow-lg border"
                    placeholder="Số điện thoại"
                    value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                    ref={input2Ref}
                    onKeyPress={(event) => handleKeyPress(event, input3Ref)}
                    />
                    <input  
                    class="w-full p-2 mt-4  shadow-lg border"
                    placeholder="Email"
                    id="email"
                    name="email"
                    value={email} onChange={(e) => setEmail(e.target.value)}
                    ref={input3Ref}
                    onKeyPress={(event) => handleKeyPress(event, input4Ref)}
                    />
                 
                  </div>
                  <div class="w-full p-1 ">
                  <textarea  
                  name="information" 
                  id="information" 
                 placeholder="Nội dung" 
                 value={information} onChange={(e) => setInformation(e.target.value)} 
                 class="p-2 shadow-lg mt-1 border w-full h-[60vh] sm:h-[50vh] lg:h-[40vh]  xs:block"
                 ref={input4Ref}
                onKeyPress={(event) => handleKeyPress(event, null)}
                 ></textarea>
                     </div>          
                  <div class="max-w-[300px]">
                  <button class="mt-4 p-3 w-[100%] ml-auto mr-auto  bg-pur text-white font-extrabold rounded shadow-lg "  type="submit" >Gửi thông tin</button>
                  </div>
               
               </form>
               </div>
             </div>
          </div>

          <div class="mt-10 w-full drop-shadow  shadow-[0_3px_20px_rgba(0,_0,_0,_0.6)]">
          <iframe
          title="Embedded Content"
          width="100%"
          height="400"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.8001119690807!2d106.64270007439711!3d10.749883259684099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752e89c03df09b%3A0x4871b8bc08dd7583!2zMTI0IMSQLiBI4bqtdSBHaWFuZywgUGjGsOG7nW5nIDYsIFF14bqtbiA2LCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1694322345083!5m2!1svi!2s"
          frameBorder="0"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          allowFullScreen
          id="myIframe" // Add an ID to the iframe
        ></iframe>

          </div>
          {/* <div class="grid p-3 grid-cols-1 mt-10 lg:grid-cols-3 lg:p-0">
             <div class="p-2 col-span-2">
               <h1 class="font-bold border-b pb-3 text-[22px]">Liên hệ chúng tôi </h1>
               <form class="flex h-[100%] mt-5  justify-between">
                  <div class="w-[100%] p-1 pr-3 xs:w-[50%]">
                    <input  
                    class="w-full p-2 shadow-lg"
                    placeholder="Họ và tên"
                    />
                    <input  
                    class="w-full p-2 mt-4 shadow-lg"
                    placeholder="Số điện thoại"
                    />
                    <input  
                    class="w-full p-2 mt-4  shadow-lg"
                    placeholder="Email"
                    />
                    <button class="mt-4 p-3 bg-pur text-white font-bold rounded shadow">Gửi thông tin</button>
                  </div>
                   <textarea name="" id="" cols="45" rows="5" placeholder="Nội dung" class="p-3 shadow-lg mt-1  hidden xs:block"></textarea>
                  
               </form>
             </div>
             <div class="p-2 col-span-1">
                 <h1 class="border-b font-bold pb-3 text-[22px]">Thông tin tin liên hệ</h1>
                 <div class="flex mt-[16px]">
                  <div class="border  w-[30px] h-[30px] flex mt-2 bg-pur  justify-center items-center ml-2">
                  <MdOutlineLocationOn class="mt-1 text-white text-[18px] shadow-lg"/>
                  </div>
                  
                  <div class="ml-4">
                     <p class="font-bold">Địa chỉ: </p>
                     <p class="mt-[-2px]">124 Hậu Giang, phường 6, quận 6</p>
                  </div>
                </div>
                <div class="flex mt-2">
                  <div class="border p-1 w-[30px] h-[30px] flex mt-2 bg-pur justify-center items-center ml-2">
                  <AiOutlineMail class="mt-1 text-white text-[18px] shadow-lg"/>
                  </div>
                  
                  <div class="ml-4">
                     <p class="font-bold">Email: </p>
                     <p class="mt-[-2px]">thctinhao@gmail.com</p>
                  </div>
                </div>
                 <div class="flex mt-2">
                  <div class="border p-1 w-[30px] h-[30px] flex mt-2 bg-pur  justify-center items-center ml-2">
                  <AiOutlinePhone class="mt-1 text-white text-[18px] shadow-lg"/>
                  </div>
                  
                  <div class="ml-4">
                     <p class="font-bold">Liên hệ: </p>
                     <p class="mt-[-2px]">0283-9625-428</p>
                  </div>
              
                </div>
                <div class="p-2">
                   <h2 class="font-bold mb-2">Ghé thăm chúng tôi tại shopee</h2>
                    <img class="w-[150px]" src="https://ik.imagekit.io/39wvgoqre/THC%20/QR_shopee.png?updatedAt=1716629157257" alt="shopee_url" />
                </div>
             </div>
          </div> */}
       </div>
    )
  
}

export default Contact;