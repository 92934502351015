import axios from 'axios';
import {Link, useParams} from "react-router-dom"
import { useState, useEffect } from 'react';


const AboutUsContent = () => {
   return (
      <div class="h-[auto] bg-[#f2f2f2] "> 
         <div class="h-[auto]   bg-[url('https://ik.imagekit.io/39wvgoqre/THC%20/freepik_830557411.jpg?updatedAt=1716895167821')] bg-cover bg-center  shadow-[0px_10px_1px_rgba(221,_221,_221,_1),_0_10px_20px_rgba(204,_204,_204,_1)]">
             <div class="text-[#fff] max-w-[1200px] h-[80vh] ml-auto mr-auto  flex justify-start pb-[140px] items-end "> </div>
         </div>
         <div class="h-[auto] max-w-[1200px] ml-auto mr-auto p-4">
              <h1 class="mt-[60px] font-extrabold sm:text-[30px] text-[25px]">Chuyên Gia Về Giải Pháp Làm Sạch Nhà Cửa</h1>
              <h2 class="mt-[10px] sm:text-[20px] text-[18px] font-bold">Hơn 10 Năm Kinh Nghiệm - Uy Tín & Chất Lượng </h2>
              <p class="sm:text-[18px] text-[16px] mt-2 max-w-[900px] mb-[60px]">Công ty TNHH Tín Hào, với thương hiệu THC-Cleaning, tự hào là người bạn đồng hành tin cậy của mọi gia đình Việt trong hành trình kiến tạo không gian sống sạch sẽ, thoáng đãng. Với hơn một thập kỷ kinh nghiệm trong lĩnh vực sản xuất và phân phối dụng cụ làm sạch, chúng tôi không ngừng nỗ lực mang đến những sản phẩm chất lượng cao, đáp ứng mọi nhu cầu đa dạng của khách hàng.</p>
            
         </div>
         <div class="text-[#fff]          bg-[url('https://ik.imagekit.io/39wvgoqre/439314029_122103598880283386_1118189370891640280_n.png?updatedAt=1716896469184')]         bg-[url('https://ik.imagekit.io/39wvgoqre/439314029_122103598880283386_1118189370891640280_n.png?updatedAt=1716896469184')]  bg-cover bg-center h-[400px] ml-auto mr-auto  flex justify-start items-end "> 

         {/* <img class="h-[500px]" src="https://scontent.fsgn8-4.fna.fbcdn.net/v/t39.30808-6/437970825_122103585428283386_4632397078227949224_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=hxStqI3e33IQ7kNvgEVxIDy&_nc_ht=scontent.fsgn8-4.fna&oh=00_AYBgZUZ5JlaEp1rf0vIJwUocnQL7QGIm9uYwtzQpXLdB_A&oe=665DCEF5 " /> */}
          </div>
         <div class="min-h-[200px] max-w-[1200px] ml-auto mr-auto p-4">
              <h2 class="mt-[60px] text-[20px] font-bold">Sản Phẩm Đa Dạng, Thiết Kế Thông Minh </h2>
              <p class="text-[18px] mt-2 max-w-[900px] mb-[10px]">THC-Cleaning thấu hiểu rằng mỗi không gian sống đều có những đặc thù riêng. Chính vì vậy, chúng tôi mang đến một bộ sưu tập sản phẩm đa dạng, từ truyền thống đến hiện đại, từ đơn giản đến thông minh, giúp bạn dễ dàng lựa chọn giải pháp phù hợp nhất:</p>
              <ul class="text-[18px]">
                 <li class="mb-2"> <span class="font-bold ">Bộ lau nhà thông minh:</span> Tích hợp công nghệ tiên tiến, giúp lau sạch mọi bề mặt sàn chỉ trong nháy mắt, tiết kiệm thời gian và công sức.</li>
                 <li class="mb-2"><span class="font-bold">Cây lau nhà đa năng:</span>  Thiết kế linh hoạt, điều chỉnh độ dài và góc lau dễ dàng, len lỏi vào mọi ngóc ngách khó tiếp cận.</li>
                 <li class="mb-2"><span class="font-bold">Chổi lau nhà chuyên dụng: </span>  Đa dạng chất liệu và kiểu dáng, phù hợp với từng loại sàn, từ gạch men, gỗ đến đá hoa cương.</li>
                 <li class="mb-2"><span class="font-bold">Bông lau nhà siêu thấm: </span>  Chất liệu microfiber cao cấp, thấm hút vượt trội, không để lại bụi vải, cho sàn nhà luôn sạch bong kin kít.</li>
                 <li class="mb-2"><span class="font-bold">Và nhiều hơn thế nữa:</span>  Xô lau nhà tiện lợi, gạt nước thông minh, chổi quét đa năng,... Tất cả đều được thiết kế tỉ mỉ, đảm bảo độ bền và hiệu quả sử dụng tối ưu.</li>
              </ul>
         </div>
         <div class="max-w-[1200px] grid sm:grid-cols-3 grid-cols-1  ml-auto mr-auto gap-4 min-h-[200px] p-4">
            <div class="overflow-hidden cursor-pointer shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(48,_81,_161,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)]">
              <img class="hover:scale-110 duration-300 rounded" src="https://ik.imagekit.io/39wvgoqre/THC%20/mop.jpg?updatedAt=1716897157782" />
            </div>
            <div class="overflow-hidden cursor-pointer shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(48,_81,_161,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)]">
              <img class="hover:scale-110 duration-300 rounded" src="https://ik.imagekit.io/39wvgoqre/THC%20/mop2.jpg?updatedAt=1716897157617" />
            </div>
            <div class="overflow-hidden cursor-pointer shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(48,_81,_161,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)]">
              <img class="hover:scale-110 duration-300 rounded" src="https://ik.imagekit.io/39wvgoqre/THC%20/mop3.jpg?updatedAt=1716897157598" />
            </div>
         </div>
      </div>

   )
}

export default AboutUsContent;