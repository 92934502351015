import {useState,useEffect} from "react"
import { UseSelector, useSelector } from "react-redux"
import Header from "../../component/header/header.jsx";
import OrderHistory from "../../component/content/orderHistory.jsx"
import Footer from "../../component/footer/footer.jsx"
import axios from "axios";

const MyOrder = () => {
 
    return (
        <div>
            <Header />
            <OrderHistory />
            <Footer />
        </div>
    )
}

export default MyOrder;