import {useState,useEffect} from "react"
import { UseSelector, useSelector } from "react-redux"
import {Link} from "react-router-dom"
import axios from "axios";

const OrderHistory = () => {
    const userName = useSelector((state) => state.root.auth.login.currentuser);
    const name = userName?.user?.name; 
    const ApiThc = "https://test-api-bhc7.onrender.com"
    const [orders, setOrders] = useState([]);
    function formatPrice(priceValue) {
  
      return priceValue.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0, 
        maximumFractionDigits: 0,
      });
    }
    // Create Date
    function formatCreatedAt(createdAt) {
      const date = new Date(createdAt);
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${month}-${day}-${year}`;
    }
    useEffect(() => {
      if (name) {
        axios
          .get(`${ApiThc}/api/order/my-order/${name}`)
          .then((response) => {
            setOrders(response.data.orders);
          })
          .catch((error) => {
            console.error('Error fetching orders:', error);
          });
      }
    }, [name]);
    return (
        <div class="mt-[200px] min-h-[100vh] max-w-[900px] ml-auto mr-auto p-2">
           <div>
              <h1 class="text-[28px] font-bold">Lịch sử đơn hàng </h1>
              <p>Kiểm tra và xem lại đơn hàng của bạn</p>
           </div>
        
           <div class="min-h-[500px] mt-2 w-full   rounded-lg"> 
        
            <div>
            {
              orders ? (
                <div class="w-full">
                {orders.map((order) => (
                  <div key={order._id} className="grid grid-col-5 w-full rounded-lg shadow-lg bg-white mb-10 ">
                    <div class=" p-2 bg-[#f0f2f2] w-full grid grid-cols-3">
                    <p><span class="font-bold col-span-2"> Ngày đặt: </span> {formatCreatedAt(order.createdAt)}</p>
                    <p><span class="font-bold col-span-2"> Địa chỉ: </span> {order.address}</p>
                      <p class="text-right"> <span class="font-bold"> Thành tiền: </span>{formatPrice(order.totalPrice)}</p>
                    </div>
                  
                    <div>
                      {order.products.map((product,index) => (
                           <div key={product._id} class={`flex p-2 items-center ${index === order.products.length - 1 ? 'border-b-0' : 'border-b-2 border-dashed'} `}>
                    
                           <img src={product.photo} class="w-[100px]"/>
                           <div class="p-2 w-full flex justify-between">
                            <div>
                            <p class="font-bold">{product.name} {product.color.length !== 0 ? (<span>({product.color})</span>): null}</p> 
                            <p><span class="font-bold">Số lượng:</span> {product.quantity}</p>
                            </div>
                            <div>
                              <Link to={`/product/${product.slug}`}> <p class="duration-300 border-2 rounded shadow-lg w-[150px] hover:bg-[#000] hover:w-[180px] hover:text-white p-2 text-center font-bold  mt-10">Đặt lại</p></Link> 
                            </div>
                           </div>
                           
                         </div>
                     
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              ) : <p>Loading</p>
            } 
             
           </div>
           </div>
        </div>
    )
}

export default OrderHistory;