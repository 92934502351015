
import { useSelector,useDispatch } from 'react-redux';
import {useState} from 'react'
import { addToCart, logOut } from "../../redux/action/apiRequest";
import {HiOutlineTrash} from "react-icons/hi"
import {AiOutlineClose} from "react-icons/ai"
import { removeFromCart } from "../../redux/action/apiRequest";
import "../../style/scoll.css"
import "../../style/animation.css"
const Cart = ({ animationClass }) => {
  const cart = useSelector((state) => state.product.cart);
  const dispatch = useDispatch();

  return (
    <div class={`w-[100%] ${animationClass} scoll overflow-y-auto h-[100vh] shadow-xl top-[0%] sm:w-[600px] right-[0%] z-50 p-3 text-[#000] bg-white fixed`}>
      <div class="overflow-auto">
        <h1 class="text-[22px] font-bold mb-5">Lưu sản phẩm</h1>
        <div class="border-t-2 border-dashed pt-5">
          {cart?.cartItems ? ( // Use optional chaining here
            cart.cartItems.map((item) => (
              <div className="text-[#000] w-full mt-6 flex" key={item._id}>
                <div class="max-w-[120px]">
                  <img src={item.photo} class="w-[100%] border-2" alt="" srcset="" />
                </div>
                <div class="w-full flex justify-center flex-col relative pl-3 h-auto " key={item.name}>
                  <div class="mb-5">
                    <p class="font-bold text-[16px] md:text-[18px] mb-[3px] ">{item.name}</p>
                  </div>
                  <div>
                    <p class="font-bold">Mã sản phẩm: <span class="font-medium">{item.codeProduct}</span></p>
                    <p class="font-bold">Phân loại: <span class="font-medium">{item.category}</span></p>
                    <p onClick={() => dispatch(removeFromCart(item.slug))}><HiOutlineTrash class="absolute text-[25px] cursor-pointer bottom-0 right-0" /></p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Không có sản phẩm trong mục.</p>
          )}
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

export default Cart;