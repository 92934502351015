import axios from "axios";
import { loginStart,loginSuccess,loginFailed,registerStart,registerSuccess,registerFailed,logoutStart,logoutSuccess,logoutFailed } from "../reducer/authSlice";
import {  FETCH_PRODUCTS_REQUEST, FETCH_PRODUCTS_SUCCESS,FETCH_PRODUCTS_FAILURE,FETCH_PRODUCT_DETAILS_REQUEST,FETCH_PRODUCT_DETAILS_SUCCESS,FETCH_PRODUCT_DETAILS_FAIL ,FETCH_PRODUCT_DETAILS_RESET} from "../constant/productConstant"
import {ADD_TO_CART,REMOVE_FROM_CART,UPDATE_QUANTITY,RESET_CART }from '../constant/cartConstant';
import { CREATE_ORDER_SUCCESS,CREATE_ORDER_FAILURE,CREATE_ORDER_REQUEST} from "../constant/orderConstant";


const ApiTHC = "https://test-api-bhc7.onrender.com";
export const loginUser = async (user,dispatch,navigate) => {
    dispatch(loginStart());
    
    try {
        const res = await axios.post(`${ApiTHC}/api/auth/signin`,user)
        dispatch(loginSuccess(res.data));
        navigate("/")

    } catch (error) {
        dispatch(loginFailed())
    
    }
}

export const registerUser = async (user,dispatch,navigate) => {
    dispatch(registerStart());
    
    try {
        const res = await axios.post(`${ApiTHC}/api/auth/register`,user)
        dispatch(registerSuccess());
        navigate("/user/signin")

    } catch (error) {
        dispatch(registerFailed())
   
    }
}

export const logOut = async (dispatch,navigate,id) => {
    dispatch(logoutStart());
    try {
           await axios.post(`${ApiTHC}/api/auth/logout`,id);
        dispatch(logoutSuccess());
    } catch (error) {
        dispatch(loginFailed());
        console.log(error);
    }
}

export const getProducts = () => async (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS_REQUEST });
    const response = await axios.get(`${ApiTHC}/api/product`);
    try {

      dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_PRODUCTS_FAILURE, payload: error.message });
    }
  }

export const getProductDetail = (slug) => async (dispatch) => {
    dispatch({type:FETCH_PRODUCT_DETAILS_REQUEST});
    const response = await axios.get(`${ApiTHC}/api/product/${slug}`)
    try {
        dispatch({type:FETCH_PRODUCT_DETAILS_SUCCESS,payload:response.data});
    } catch (error) {
        dispatch({type:FETCH_PRODUCT_DETAILS_FAIL,payload:error.message})
    }
}

export const addToCart = (slug,quantity,color) => async (dispatch,getState) => {
    const res = await axios.get(`${ApiTHC}/api/product/${slug}`)
    try {
        dispatch({
            type:ADD_TO_CART,
            payload:{
              id:res.data.id,
              slug:res.data.slug,
              name:res.data.name,
              photo:res.data.photo,
              price:res.data.price,
              category:res.data.category_name,
              codeProduct:res.data.description,
            
              color:color
            },
         
         })
         localStorage.setItem("cart", JSON.stringify(getState().product.cart.cartItems));
    } catch (error) {
        return error
    }
     
       
}


export const removeFromCart = (slug) => (dispatch, getState) => {
    try {
        dispatch({
            type: REMOVE_FROM_CART,
            payload: slug,
          });
        
          localStorage.setItem("cart", JSON.stringify(getState().product.cart.cartItems));
    }catch (error) {
        return error
    }
  
};

export const resetCart = () => ({
    type: RESET_CART,
  });

  export const createOrder = (orderData) => {
    return async (dispatch) => {
      dispatch({ type: CREATE_ORDER_REQUEST });
      try {
        const response = await axios.post(`${ApiTHC}/api/order`, orderData);
  
        if (response.status === 201) {
          // Dispatch a success action if the order creation was successful
          dispatch({
            type:CREATE_ORDER_SUCCESS,
            payload:response.data,
          });
        } else {
          // Dispatch a failure action if there was an error
          dispatch({
            type: CREATE_ORDER_FAILURE,
            payload: 'Order creation failed', // Include an error message if needed
          });
        }
      } catch (error) {
        // Handle any network errors here
        dispatch({
          type: 'CREATE_ORDER_FAILURE',
          payload: 'Network error', // Customize the error message as needed
        });
      }
    };
  };