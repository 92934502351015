import {useState,useEffect} from 'react'
import {Link,useParams} from 'react-router-dom'
import axios from 'axios'
const ApiTHC = "https://test-api-bhc7.onrender.com";
const  SubProduct = () => {
  const [products,setProducts] = useState([]);
  useEffect(() => {
    // Fetch product data ofrm your API endpoint
    axios.get(`${ApiTHC}/api/products/phu-kien`)
      .then((response) => setProducts(response.data.products)) // Assuming the product data is under the "products" key
      .catch((error) => console.error('Error fetching products:', error));
  }, []);
    return (
      <div>
      <div class="bg-[#fff] p-2 shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(0,_98,_90,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)]">
        <div class="max-w-[1200px] grid sm:grid-cols-2 grid-cols-1 mr-auto ml-auto px-3">
           <div class="text-left flex flex-col items-start justify-center">
           <h2 class="font-bold text-[23px] sm:pt-0 pt-10 mb-5">Chổi quét và bông lau</h2>
           <p class=" ml-auto mr-auto">Chất liệu bền bỉ và thiết kế độc đáo là những đặc điểm nổi bật của chúng tôi. Dù bạn cần làm sạch những vùng nhỏ hẹp hay diện tích rộng lớn và Bông lau của chúng tôi không chỉ làm sạch mà còn giữ được chất lượng sau nhiều lần sử dụng. Sự mềm mại và khả năng hấp thụ tốt, đồng thời với thiết kế tiện lợi.</p>
           </div>
    
       
      <div class="text-center max-w-[800px]">
         <a href="https://shopee.vn/product/1071087389/20293759994"><img class="w-[100%]" src="https://ik.imagekit.io/39wvgoqre/THC%20/CO%20GAI.jpg?updatedAt=1716293585229" /></a>
      </div>
      </div>
        </div>
        <div class="max-w-[1200px] ml-auto mr-auto p-2">
      
     
      <div class="grid grid-cols-2 lg:grid-cols-5 sm:grid-cols-3 gap-4 p-2 mb-4 mt-5">
      {products.map((product) => (
      
      <div key={product._id} className="flex border border-[#0004] ">
      <Link to={`/product/${product.slug}`} class="">
       <div class="group oveflow-hidden min-h-[380px]   shadow-lg relative overflow-hidden cursor-pointer duration-300">
       <div className="max-w-[350px] flex items-center relative">
       <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-[1] group-hover:animate-shine" />
      <img
        src={product.photo}
        className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-100 group-hover:opacity-0 transition-opacity duration-300 mt-5"
        alt=""
      />
     <img
       src={product.sub_photo}
       className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 left-[50%] translate-x-[-50%] mt-5"
       alt=""
      />
    </div>
       
        <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
           <div class="p-2">
     
           <h1 class="font-bold mb-0  group-hover:text-pur duration-300 mt-3">{product.name }</h1>
            <div class=" font-medium flex justify-between item-end absolute bottom-0 left-0 right-0 px-2 pb-3 ">
            <p>TPHCM</p>
            <p> {product.description }</p>
            </div>
           </div>
       </div>
       </Link>
      </div>
    ))} 
      
 
     </div>

     </div>
      </div>
     
    )
}

export default SubProduct