import {useState,useEffect} from 'react'
import {Link,useParams} from 'react-router-dom'
import axios from 'axios'
const ApiTHC = "https://test-api-bhc7.onrender.com";
const  MutProduct = () => {
  const [products,setProducts] = useState([]);
  useEffect(() => {
    // Fetch product data ofrm your API endpoint
    axios.get(`${ApiTHC}/api/products/mut`)
      .then((response) => setProducts(response.data.products)) // Assuming the product data is under the "products" key
      .catch((error) => console.error('Error fetching products:', error));
  }, []);
    return (
      <div class="max-w-[1200px] ml-auto mr-auto p-2">
        <div class=" grid grid-cols-1 md:grid-cols-2 gap-8 mb-10 mt-[20px] ">
          <div>
           <div class="overflow-hidden p-2 mr-[15px] md:mr-[0px] group cursor-pointer relative shadow-[5px_5px_rgba(48,_81,_161,_0.4),_10px_10px_rgba(48,_81,_161,_0.3),_15px_15px_rgba(48,_81,_161,_0.2),_20px_20px_rgba(0,_98,_90,_0.1),_25px_25px_rgba(48,_81,_161,_0.05)]">
            <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
             <a href="https://shopee.vn/product/1071087389/19393267595"><img class=" duration-300" src="https://ik.imagekit.io/39wvgoqre/THC%20/banner%20/BANNER%20PNG/BANNER%20PNG/TH%20108.png?updatedAt=1701931963458" alt="" srcset="" /></a>
           </div>
           </div>
           <div class="ml-2 flex flex-col justify-center items-center">
             <div class="md:mt-2 mt-5">
              <h2 class="text-[16px] font-bold mb-2">Mút Lau Nhà Chất Lượng, Hiệu Suất Xuất Sắc </h2>
              <p>- Khám phá bộ sưu tập mút lau nhà của chúng tôi, nơi chất lượng vượt trội và hiệu suất làm sạch đỉnh cao. Thiết kế độc đáo giúp đơn giản hóa công việc lau dọn, mang đến trải nghiệm thuận lợi và hiệu quả..</p>
             </div>
             <div class="md:mt-2 mt-5">
              <h2 class="text-[16px] font-bold mb-2">Lựa Chọn Số 1 Cho Việc Lau Nhà</h2>
              <p>- Tận hưởng sự đa dạng và chất lượng với mút lau nhà chất liệu cao cấp từ chúng tôi. Sản phẩm được thiết kế để đáp ứng mọi nhu cầu lau dọn của bạn, đảm bảo sự sạch sẽ và tiện lợi..</p>
             </div>
             <div class="md:mt-2 mt-5">
              <h2 class="text-[16px] font-bold mb-2">Mua Chổi Lau Nhà, Nhận Ngay Trải Nghiệm Hoàn Hảo</h2>
              <p>- Đặt hàng ngay để trải nghiệm sự tiện lợi và độ bền của mút lau nhà chuyên nghiệp của chúng tôi. Chúng tôi cam kết mang đến cho bạn trải nghiệm lau nhà hiệu quả và không gì sánh kịp..</p>
             </div>
           </div>
         
           
        </div>
            <div class="grid grid-cols-2 lg:grid-cols-5 sm:grid-cols-3 gap-4 mb-4 mt-5 ">
            {products.map((product) => (
            
            <div key={product._id} className="flex border border-[#0004] ">
            <Link to={`/product/${product.slug}`} class="">
             <div class="group oveflow-hidden min-h-[380px]   shadow-lg relative overflow-hidden cursor-pointer duration-300">
             <div className="max-w-[350px] flex items-center relative">
             <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-[1] group-hover:animate-shine" />
            <img
              src={product.photo}
              className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-100 group-hover:opacity-0 transition-opacity duration-300 mt-5"
              alt=""
            />
           <img
             src={product.sub_photo}
             className="rounded-sm w-[60%] ml-auto mr-auto shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 left-[50%] translate-x-[-50%] mt-5"
             alt=""
            />
          </div>
             
              <div className="absolute top-0 -inset-full h- ease-out w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-[#f2f2f2] to-white opacity-10 group-hover:animate-shine" />
                 <div class="p-2">
           
                 <h1 class="font-bold mb-0  group-hover:text-pur duration-300 mt-3">{product.name }</h1>
                  <div class=" font-medium flex justify-between item-end absolute bottom-0 left-0 right-0 px-2 pb-3 ">
                  <p>TPHCM</p>
                  <p> {product.description }</p>
                  </div>
                 </div>
             </div>
             </Link>
            </div>
          ))} 
            
       
           </div>
           </div>
    )
}


export default MutProduct;