import HomePage  from './page/mainPage/homepage';
import BestSold from './page/mainPage/bestsellerpage'
import AboutUs  from './page/mainPage/aboutuspage'
import  PageDetail from './page/mainPage/pageDetail'
import NotFound from './page/errorPage/notRegister'
import Categorypage from './page/mainPage/categorypage';
import ContactPage from './page/subPage/contactPage';
import MyOrder from './page/subPage/myorderPage';
import SearchPage from './page/mainPage/searchPage';


export const routes = [
  { path: '/', element:  <HomePage /> },
  { path: '/bestSold', element: <BestSold /> },
  { path: '/aboutUs', element: <AboutUs /> },
  { path: '/product/:slug', element: <PageDetail /> },
  { path: '/product/category/:slug', element: <Categorypage /> },
  { path: '/contact', element: <ContactPage /> },
  { path: '/search/:slug', element: <SearchPage /> },
  { path: '/my-order', element: <MyOrder /> },
  { path: '*', element: <NotFound /> },
];