import Header from "../../component/header/header";
import Category from "../../component/content/category";
import Socialbox from '../../component/content/socail.jsx'
import Footer from "../../component/footer/footer";
const Categorypage = () => {
    return (
        <div>
           <Header />
           <Category />
           <Socialbox />
           <Footer />
        </div>
    )
}

export default  Categorypage;